import {Totp} from "./jsOTP"

export default class MeteosourceApiProtect {

    #apiPrefix
    #initStr
    #secret
    #totp
    #onChangeListeners = []
    #lastApiKey

    constructor(apiPrefix) {
        this.#apiPrefix = apiPrefix
        let s = []
        for(let i=0; i<10; i++)
            s.push(String.fromCharCode(97 + Math.random()*(122-97)))
        this.#initStr = s.join("")
    }

    async init() {
        let addr = this.#apiPrefix + "/_generate_secret?init_str=" + this.#initStr
        let res = await fetch(addr)
        if(!res.ok)
            throw new Error()
        this.#secret = await res.json()
        this.#totp = new Totp(5*60)

        this.#periodicApiKeyUpdate()
        setInterval(this.#periodicApiKeyUpdate, 60*1000);
    }

    #periodicApiKeyUpdate = () => {
        let newApiKey = this.getApiKey();
        if(newApiKey !== this.#lastApiKey) {
            this.#lastApiKey = newApiKey;
            for(let f of this.#onChangeListeners)
                f(newApiKey);
        }
    }

    getApiKey() {
        let now = new Date().getTime()
        let times = [now - 5*60*1000, now, now + 5*60*1000]
        let otps = times.map(t => this.#totp.getOtp(this.#secret, t))
        let key = this.#initStr + "," + otps.join(",")
        return key
    }

    onApiKeyChange(f) {
        this.#onChangeListeners.push(f)
    }
}