import {transform, transformExtent} from "ol/proj";
import olms, {applyStyle} from "ol-mapbox-style";
import JQueryNodes from "./JQueryNodes";
import {DragRotate, PinchRotate} from "ol/interaction";
import {View} from "ol";
import VectorTileLayer from 'ol/layer/VectorTile.js'


/**
 * Singleton
 */
export default class MapController {

	/**
	 * Constructor
	 * @param {function} mapViewChangedCb
	 * @param {function} editUrlParamsCb
	 * @param {MeteosourceApiProtect} apiProtect
	 * @param {PlaceBubbleController} placeBubbleController
	 */
	constructor(mapViewChangedCb, editUrlParamsCb, apiProtect, placeBubbleController) {
		this.#mapViewChangedCb = mapViewChangedCb;
		this.#editUrlParamsCb = editUrlParamsCb;
		this.#apiProtect = apiProtect;
		this.#endpointUrl = import.meta.env.VITE_API_PREFIX + "nearest_place"
		this.#placeBubbleController = placeBubbleController
	}

	/**
	 * @private
	 * @type {PlaceBubbleController}
	 */
	#placeBubbleController;

	/**
	 * @private
	 * @type {MeteosourceApiProtect}
	 */
	#apiProtect;

	/**
	 * @private
	 * @type {string}
	 */
	#endpointUrl;

	/**
	 * @private
	 * Callback for edit url params
	 * @type {function}
	 */
	#editUrlParamsCb

	/**
	 * Storest forecast layer
	 */
	#forecastLayer;

	/**
	 * @private
	 * Get map. It save response from **olms** from initMap()
	 * @returns {Map}
	 */
	#map;

	get map() {
		return this.#map;
	}

	#getCurrentCoord = (num, postfixPositive, postfixNegative) => {
		let val = transform(this.#map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')[num];
		let postfix = val >= 0 ? postfixPositive : postfixNegative;
		return Math.abs(val).toFixed(2) + postfix;
	}

	get currentLat() {
		return this.#getCurrentCoord(1, "N", "S")
	}

	get currentLon() {
		return this.#getCurrentCoord(0, "E", "W")
	}

	/**
	 * @private
	 * Callback for change map viewport
	 * @type {function}
	 */
	#mapViewChangedCb

	#loadStyleJson = async path => {
		let styleUrl = import.meta.env.VITE_HOST_URL + import.meta.env.BASE_URL + path;
		let styleJson = await (await fetch(styleUrl)).json();
		styleJson.sprite = import.meta.env.VITE_SPRITE_URL;
		styleJson.glyphs = import.meta.env.VITE_FONTS_URL;
		styleJson.sources.openmaptiles.url = styleJson.sources.openmaptiles.url.replace("PLANET_URL", import.meta.env.VITE_PLANET_URL);
        if(styleJson.sources.openmaptiles_restricted)
			styleJson.sources.openmaptiles_restricted.url = styleJson.sources.openmaptiles_restricted.url.replace("PLANET_URL", import.meta.env.VITE_PLANET_URL);
		return styleJson
	}

	/**
	 * @public
	 * Get map init like (init center point, init zoom, etc.)
	 */
	init = async (lat = null, lon = null) => {
		if(lat === null)
			lat = 50.7666611
		if(lon === null)
			lon = 15.06

		let styleJsonOver = await this.#loadStyleJson("style-custom-over.json")
		let styleJsonUnder = await this.#loadStyleJson("style-custom-under.json")

		const layerOver = new VectorTileLayer({declutter: true, className: "over"});
		const layerOverRestricted = new VectorTileLayer({declutter: true, className: "over"});
		await applyStyle(layerOver, styleJsonOver, "openmaptiles")
		await applyStyle(layerOverRestricted, styleJsonOver, "openmaptiles_restricted")

		return olms("olMap", styleJsonUnder)
			.then((map) => {
				window.olMap = map;

				let interactionsToRemove = []
				for(let interaction of map.getInteractions().getArray()) {
					if(interaction instanceof DragRotate || interaction instanceof PinchRotate)
						interactionsToRemove.push(interaction)
				}
				for(let interaction of interactionsToRemove)
					map.removeInteraction(interaction)

				map.addLayer(layerOver)
				map.addLayer(layerOverRestricted)

				let viewProps = map.getView().getProperties()
                viewProps.extent = transformExtent([-180, -80, 180, 80], "EPSG:4326", "EPSG:3857")
				viewProps.smoothExtentConstraint = false
				let view = new View(viewProps)
				map.setView(view)

				// set init center point
				map.getView().setCenter(transform([lon, lat], "EPSG:4326", "EPSG:3857"));

				// set init zoom
				map.getView().setZoom(4);

				// set max zoom in
				map.getView().setMaxZoom(8);

				// map view port changed listener
				map.on("moveend", this.#mapViewChangedCb);

				map.addOverlay(this.#placeBubbleController.getOverlay());

				// cache
				this.#map = map;
				window.view = map.getView()


				map.getLayers().forEach(layer => {
					if("getSource" in layer && layer.getSource() !== null && layer.getSource() !== undefined &&
							"getUrls" in layer.getSource()) {
						let urls = [...layer.getSource().getUrls()]
						let newUrls = urls.map(url => url.replace("METEOSOURCE_API_KEY", this.#apiProtect.getApiKey()).replace("METEOSOURCE_API_PREFIX", import.meta.env.VITE_API_PREFIX))
						layer.getSource().setUrls(newUrls)
						this.#apiProtect.onApiKeyChange(newApiKey => {
							let newUrls = urls.map(url => url.replace("METEOSOURCE_API_KEY", newApiKey).replace("METEOSOURCE_API_PREFIX", import.meta.env.VITE_API_PREFIX))
							layer.getSource().setUrls(newUrls)
						})
					}
					layer.setZIndex(10000000000)
				})

				layerOver.setZIndex(20000000000)
				layerOverRestricted.setZIndex(20000000000)
				layerOverRestricted.getSource().tileGrid.getResolutions().length = 6
				this.#placeBubbleController.getLayer().setZIndex(30000000000)
			});
	}

	/**
	 * @private
	 * Zoom map on target point
	 * @param {Number} lat
	 * @param {Number} lon
	 */
	placeZoom = (lat, lon) => {
		// prepare variables
		let replaceLon;
		let replaceLat;

		// calc lon
		if(typeof lon === "number") {
			replaceLon = lon;
		} else if(lon.indexOf('W') > -1) {
			replaceLon = -lon.replace(/[^\d.-]/g,'');
		} else {
			replaceLon = lon.replace(/[^\d.-]/g,'');
		}

		// calc lat
		if(typeof lon === "number") {
			replaceLat = lat;
		} else if(lat.indexOf('S') > -1) {
			replaceLat = -lat.replace(/[^\d.-]/g,'');
		} else {
			replaceLat = lat.replace(/[^\d.-]/g,'');
		}

		// set center of zoom
		this.map.getView().setCenter(transform([replaceLon, replaceLat], "EPSG:4326", "EPSG:3857"));

		// set zoom
		this.map.getView().setZoom(8);

		// refresh windy
		this.#mapViewChangedCb();

		const searchNode = JQueryNodes.elements.search;
		//delete old search place
		searchNode.find(".list .searchList").empty();
		searchNode.find("input").val('');
	}

	/**
	 * Remove actual forecast layer
	 */
	switchForecastLayer = (forecastLayerA, forecastLayerB, forecastLayerWebGlA, forecastLayerWebGlB,
						   forecastLayerWebGlC) => {

		this.#map.addLayer(forecastLayerWebGlA, forecastLayerB);
		this.#map.addLayer(forecastLayerWebGlB, forecastLayerWebGlA);
		this.#map.addLayer(forecastLayerWebGlC, forecastLayerWebGlB);
		this.#map.addLayer(forecastLayerA);
		this.#map.addLayer(forecastLayerB, forecastLayerA);
		this.#forecastLayer = forecastLayerA;
	}
}