import i18next from "i18next";
import { DateTime } from "luxon";

export function parseLatLon(arg) {
	let reLat = /^([+-])?([0-9]+)(\.[0-9]+)?([NS])?$/
	let reLon = /^([+-])?([0-9]+)(\.[0-9]+)?([EW])?$/

	if(typeof(arg) !== "string")
		return [null, null];
	arg = arg.toUpperCase();
	let parts = arg.split(",");
	if(parts.length !== 2)
		return [null, null];
	let [latStr, lonStr] = parts;
	let matchLat = latStr.match(reLat);
	let matchLon = lonStr.match(reLon);
	if(matchLat === null || matchLon === null)
		return [null, null];

	let lat = Number.parseFloat(matchLat[2] + (matchLat[3] === undefined ? "" : matchLat[3]));
	if(matchLat[4] === "S")
		lat = -lat;
	if(matchLat[1] === "-")
		lat = -lat;

	let lon = Number.parseFloat(matchLon[2] + (matchLon[3] === undefined ? "" : matchLon[3]));
	if(matchLon[4] === "W")
		lon = -lon;
	if(matchLon[1] === "-")
		lon = -lon;

	if(lat < -90 || lat > 90)
		return [null, null];
	if(lon < -180 || lon > 180)
		return [null, null];

	return [lat, lon];
}

let weekdayNames = {
    "cs": ["pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota", "neděle"],
    "en": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
}
let weekdayNamesShort = {
    "cs": ["po", "út", "st", "čt", "pá", "so", "ne"],
    "en": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
}

export function formatDate(isLong, lang, date, timezone) {
        const todayStr = DateTime.now().setZone(timezone).toISO().substr(0, 10)
        const tomorrowStr = DateTime.now().setZone(timezone).plus({days: 1}).toISO().substr(0, 10)
        const dateStr = date.toISO().substr(0, 10)
        if(dateStr === todayStr)
        	return i18next.t("today")
        else if(dateStr === tomorrowStr)
        	return i18next.t("tomorrow")
        else if(isLong)
            return weekdayNames[lang][date.weekday-1] + " " + date.day + ". " + date.month + "."
        else
            return weekdayNamesShort[lang][date.weekday-1]
}