String.prototype.dateFormat = function(formatString) {

    var date = this.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})(\s([0-9]{2}):([0-9]{2}):([0-9]{2}))?$/);

    if(!date) return false;

    var y = parseInt(date[1]);
    var m = parseInt(date[2]);
    var d = parseInt(date[3]);
    var h = date[5] ? parseInt(date[5]) : 0;
    var i = date[6] ? parseInt(date[6]) : 0;
    var s = date[7] ? parseInt(date[7]) : 0;

    return formatString
        .replace(/j/g, d)
        .replace(/n/g, m)
        .replace(/Y/g, y)
        .replace(/d/g, d < 10 ? "0"+d : d)
        .replace(/m/g, m < 10 ? "0"+m : m)
        .replace(/H/g, h < 10 ? "0"+h : h)
        .replace(/i/g, i < 10 ? "0"+i : i)
        .replace(/s/g, s < 10 ? "0"+s : s);
};

window.dateFormat = function(date, format) {
    if(date === null) return null;
    return date.dateFormat(format);
}

window.in_array = function(needle, haystack) {
    return haystack.indexOf(needle) > -1;
}