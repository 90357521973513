export default class ApiCache {

    constructor(apiProtect) {
        this.#apiProtect = apiProtect
    }

    #pointCacheLat = null
    #pointCacheLon = null
    #pointCache = null
    #airQualityCache = null
    #pointCacheTimezone = null

    #pointUrl = import.meta.env.VITE_API_PREFIX + "point";
    #airQualityUrl = import.meta.env.VITE_API_PREFIX + "air_quality";
    #apiProtect
    #unit = "metric"

    #pointPromise = null

    setUnit(unit) {
        if(this.#unit !== unit)
            this.#pointCache = this.#pointCacheLon = this.#pointCacheLat = this.#pointCacheTimezone =
                this.#airQualityCache = this.#airQualityUrl = null
        this.#unit = unit
    }

    async point(lat, lon) {
        this.#pointPromise = this.#callApi(lat, lon, this.#pointPromise)
        await this.#pointPromise;
        return [this.#pointCache, this.#pointCacheTimezone]
    }

    async airQuality(lat, lon) {
        this.#pointPromise = this.#callApi(lat, lon, this.#pointPromise)
        await this.#pointPromise;
        return [this.#airQualityCache, this.#pointCacheTimezone]
    }

    async #callApi(lat, lon, promiseToWait) {
        if(promiseToWait) {
            try {
                await promiseToWait
            } catch (e) {
            }
        }

        if(!(this.#pointCacheLat === lat && this.#pointCacheLon === lon)) {
            let [fetchUtc, fetchLocal, fetchAirQualityLocal] = await Promise.all([
                this.#multiFetch(this.#pointUrl + "?key=" + this.#apiProtect.getApiKey() + "&lat=" + lat + "&lon=" + lon + "&sections=current,hourly&timezone=utc&units=" + this.#unit),
                this.#multiFetch(this.#pointUrl + "?key=" + this.#apiProtect.getApiKey() + "&lat=" + lat + "&lon=" + lon + "&sections=current&units=" + this.#unit),
                this.#multiFetch(this.#airQualityUrl + "?key=" + this.#apiProtect.getApiKey() + "&lat=" + lat + "&lon=" + lon)
            ])
            let [jsonUtc, jsonLocal, jsonAirQualityLocal] = await Promise.all([fetchUtc.json(), fetchLocal.json(), fetchAirQualityLocal.json()])
            this.#pointCache = jsonUtc
            this.#airQualityCache = jsonAirQualityLocal
            this.#pointCacheTimezone = jsonLocal.timezone
            this.#pointCacheLat = lat
            this.#pointCacheLon = lon
        }
        return [this.#pointCache, this.#airQualityCache, this.#pointCacheTimezone]
    }

    #multiFetch = async (url, params) => {
        let res
        for(let i=0; i<3; i++) {
            try {
                res = await fetch(url, params)
                if(res.ok)
                    return res
                else
                    console.error("status code for " + url + " is " + res.status)
            }
            catch(e) {
                console.error(e)
                if("name" in e && e.name === "AbortError")
                    return res
            }
            await new Promise(resolve => setTimeout(resolve, 1000))
        }
        return res
    }
}