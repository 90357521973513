import ActiveLayer from "./ActiveLayer";
import JQueryNodes from "./JQueryNodes";
import {DateTime} from "luxon"
import i18next from "i18next";


export function getAvailableTimes(timezone, activeLayer)  {
	// get cur time
	const curDate = DateTime.now().setZone(timezone);

	// prepare forecast steps
	const forecastSteps = [];

	// get last slider hour
	const lastSliderHours = activeLayer.getConfigValue(ActiveLayer.PARAM_LAST_SLIDER_HOURS);

	const firstStepRound = activeLayer.getConfigValue(ActiveLayer.PARAM_FIRST_STEP_ROUND);
	let nextDay = curDate.minus({hours: lastSliderHours})
	if(firstStepRound === "hour") {
		nextDay = nextDay.startOf("hour")
	} else if(firstStepRound === "10minutes") {
		nextDay = nextDay.startOf("minute")
		nextDay = nextDay.minus({"minutes": nextDay.minute % 10})
	} else {
		throw "unknown firstStepRound " + firstStepRound
	}

	// get player time
	const playerTime = activeLayer.getConfigValue(ActiveLayer.PARAM_PLAYER_TIME);

	// get slider step
	const sliderStep = activeLayer.getConfigValue(ActiveLayer.PARAM_SLIDER_STEP);

	// fill forecast steps with stateps
	let curDateIdx = null
	for(let i = 0; i < playerTime; i++) {
		forecastSteps.push(nextDay.valueOf());
		if(curDateIdx === null && nextDay.valueOf() >= curDate.valueOf())
			curDateIdx = i
		nextDay = nextDay.plus({minutes: sliderStep});
	}

	return [forecastSteps, curDateIdx];
}


export default class Slider {

	/**
	 * Constructor
	 * @param {ActiveLayer} activeLayer
	 * @param {function} sliderChangedCb
	 * @param {function} sliderUserChangedCb
	 * @param {ApiCache} apiCache
	 */
	constructor(activeLayer, sliderChangedCb, sliderUserChangedCb, apiProtect, apiCache) {
		this.#activeLayer = activeLayer;
		this.#sliderChangedCb = sliderChangedCb;
		this.#sliderUserChangedCb = sliderUserChangedCb;
		this.init({});
		this.#apiProtect = apiProtect
		window.slider = this
		this.#pointUrl = import.meta.env.VITE_API_PREFIX + "point";
		this.#apiCache = apiCache
	}

	#pointUrl
	#lang = "en"
	#apiCache

	#getFormatOpts = () => {
		if(this.#lang === "en")
			return {"locale": "en-US"}
		if(this.#lang === "cs")
			return {"locale": "cs-CZ"}
		throw "Slider.#getFormatOpts: strange lang " + this.#lang
	}

	/**
	 * @type {ApiProtect}
	 */
	#apiProtect

	/**
	 * Stores layer config
	 * @type {ActiveLayer}
	 */
	#activeLayer;

	/**
	 * Array of timestamps
	 * @type {[number]}
	 */
	#forecastSteps = [];

	#currentLayer = null
    #currentLat = null
	#currentLon = null
	#currentTimezone = "local"
    #isRendered = false

	/**
	 * Maximal value of slider
	 * @returns {number}
	 */
	get max() {
		return this.#sliderNode.slider("option", "max");
	}

	/**
	 * Minimal value of slider
	 * @returns {number}
	 */
	get min() {
		return this.#sliderNode.slider("option", "min");
	}

	/**
	 * Stores player config
	 * @type {Object}
	 */
	#player = {
		secondsPerHour : 0.04,
		played : false,
		interval: null
	}

	/**
	 * Callback for slider changed
	 * @type {function}
	 */
	#sliderChangedCb

	#sliderUserChangedCb

	/**
	 * jQuery node of slider instance
	 * @type {jQuery}
	 */
	#sliderNode = JQueryNodes.elements.slider;

	/**
	 * Get size of the one step
	 * @returns {number}
	 */
	get step() {
		return this.#sliderNode.slider("option", "step");
	}

	/**
	 * Store current value
	 */
	#value;
	get value() {
		return this.#value;
	}

	/**
	 * Get width of slider
	 */
	get width() {
		return this.#sliderNode.width();
	}

	/**
	 * Return Date based on value
	 * @param value
	 * @returns {DateTime}
	 */
	getDateFromValue = (value) => {
		return DateTime.fromMillis(value * 1000 * 60).setZone(this.#currentTimezone);
	}

	/**
	 * Return value of date
	 * @param {DateTime} date
	 */
	getValueFromDate = (date) => {
		return date.valueOf() / 1000 / 60;
	}

	/**
	 * Returns string date format based on value
	 * @param value
	 * @returns {string}
	 */
	#getDateStringFromValue = (value) => {
		const date = this.getDateFromValue(value);

		return this.#getFastDayMonthDate(date)
	}

	#getFastDayMonthDate = date => date.day + ". " + date.month + "."


	/**
	 * Hide mouse position label
	 */
	#hideMouseValue = () => {
		$("#mousePosition").html(``);
	}

	/**
	 * Initialization
	 */
	init = async (args) => {
		// fast track (for frames performance -- if the slider is loaded and only the value is being changed
		if(this.#isRendered && Object.keys(args).length === 1 && args.value) {
			this.#value = args.value / (1000*60)
			this.#setSliderPosition(args.value / (1000*60))
            return
		}

		let changingLayerToRain = (args.layer === "rain" && this.#currentLayer !== "rain" && args.layer)
		let changingLayerFromRain = (args.layer !== "rain" && this.#currentLayer === "rain" && args.layer)
		let changingLayerToAirQuality = (args.layer === "air_quality" && this.#currentLayer !== "air_quality" && args.layer)
		let changingLayerFromAirQuality = (args.layer !== "air_quality" && this.#currentLayer === "air_quality" && args.layer)
		let changingLayerToSignificantWeather = (args.layer === "significant_weather" && this.#currentLayer !== "significant_weather" && args.layer)
		let changingLayerFromSignificantWeather = (args.layer !== "significant_weather" && this.#currentLayer === "significant_weather" && args.layer)
        let changingLayerReload = changingLayerFromRain || changingLayerToRain || changingLayerFromAirQuality || changingLayerToAirQuality
		changingLayerReload ||= changingLayerFromSignificantWeather || changingLayerToSignificantWeather

		let value = args.value !== undefined ? Math.round(args.value / (1000*60)) : this.#value
		let lat = args.lat !== undefined ? args.lat : this.#currentLat
		let lon = args.lon !== undefined ? args.lon : this.#currentLon
		let layer = args.layer !== undefined ? args.layer : this.#currentLayer
		let lang = args.lang !== undefined ? args.lang : this.#lang

		if(lat !== this.#currentLat || lon !== this.#currentLon || lang !== this.#lang || changingLayerReload || !this.#isRendered) {
			if(lat !== null && lon !== null) {
				this.#currentTimezone = (await this.#apiCache.point(lat, lon))[1]
			} else {
				this.#currentTimezone = "local"
			}

			// prepare forecast time stamps
			let curDateIdx
			[this.#forecastSteps, curDateIdx] = getAvailableTimes(this.#currentTimezone, this.#activeLayer);
			for(let i=0; i<this.#forecastSteps.length; i++)
				this.#forecastSteps[i] = Math.floor(this.#forecastSteps[i] / (1000*60))

			// fix value
			if (changingLayerFromRain) {
				value = this.#forecastSteps[0];
			} else if (changingLayerToRain) {
				value = this.#forecastSteps[curDateIdx - curDateIdx % 10];
			} else if (!(value && this.#getStepsIdx(value) !== -1)) {
				if (this.#value && this.#getStepsIdx(value) !== -1)
					value = this.#value
				else
					value = this.#forecastSteps[0];
			}

			// render slider
			this.#value = value;
			this.#currentLayer = layer
			this.#lang = lang;
			this.#render();
			this.#isRendered = true
		}
		else if(value !== null) {
			this.#setSliderPosition(value)
		}

		// save value
		this.#value = value;
		this.#currentLat = lat
		this.#currentLon = lon
		this.#currentLayer = layer
		this.#lang = lang
	}

	#getStepsIdx = value => {
		let min = this.#forecastSteps[0]
		let max = this.#forecastSteps[this.#forecastSteps.length-1]
		if(min <= value && value <= max)
			return (value - min) / (this.#forecastSteps[1] - min)
        else
        	return -1
	}

	/**
	 * Interval callback function
	 */
	#intervalCb = timestamp => {

		let is10minutes = this.#value % 10 === 0
		let interval
		if(is10minutes && this.#currentLayer === "rain") {
			interval = this.#player.secondsPerHour * 1000 * 15 * 2
		} else if(this.#currentLayer === "rain") {
			interval = 4*this.#player.secondsPerHour * 1000 / 1.5
		} else {
			interval = this.#player.secondsPerHour * 1000
		}

		if(this.#prevTimestamp === null || timestamp - this.#prevTimestamp > interval) {
			this.#prevTimestamp = timestamp

			// get slider step
			const sliderStep = this.#activeLayer.getConfigValue(ActiveLayer.PARAM_SLIDER_STEP);

			// prepare new value
			const value = this.#sliderNode.slider("value") + sliderStep;

			// set new value
			let doneLoop = this.#setSliderPosition(value);

			if(!doneLoop && (this.#stopPlayAtTimestamp === null || this.#sliderNode.slider("value") < this.#stopPlayAtTimestamp))
				this.#player.interval = requestAnimationFrame(this.#intervalCb)
			else
				this.#stopPlayAtTimestamp = null
		} else {
			this.#player.interval = requestAnimationFrame(this.#intervalCb)
		}
	}

	#prevTimestamp = null
	#showLoadingCaption = false

	setLoadingCaption = doShow => {
		this.#showLoadingCaption = doShow
        this.#moveSlider()
	}


	/**
	 *
	 */
	#moveSlider = () => {
		const date = this.getDateFromValue(this.#value);
		const hours = date.hour;
		const minutes = date.minute < 10 ? ('0'+date.minute) : date.minute;
		const dateString = this.#getDateStringFromValue(this.#value);

		const sliderHandle = $(".ui-slider-handle");
		if(!this.#showLoadingCaption)
			sliderHandle.html(`<div class="slider-label">${dateString} - ${hours}:${minutes}</div>`);
		else
			sliderHandle.html(`<div class="slider-label"><strong>${i18next.t("loading...")}</strong></div>`);
	}

	/**
	 * Start autoplay -> set interval
	 */
	#playForecast = () => {
		this.#prevTimestamp = null
		this.#player.interval = requestAnimationFrame(this.#intervalCb);
	}

	/**
	 * Render slider
	 */
	#render = () => {
		this.#sliderNode.slider({
			value: this.#value,
			min: this.#forecastSteps[0],
			max: this.#forecastSteps[this.#forecastSteps.length - 1],
			step: this.#activeLayer.getConfigValue(ActiveLayer.PARAM_SLIDER_STEP),
			animate: "slow",
			change: function(e, ui) {
				this.#value = ui.value;
				this.#moveSlider();
				this.#sliderChangedCb();
				this.#hideMouseValue();
			}.bind(this),
			slide: (e, ui) => {
				let roundToMinutes = (this.#currentLayer === "rain") ? 10 : 60
				let roundedValue = Math.round(ui.value / roundToMinutes) * roundToMinutes
				this.#setSliderPosition(roundedValue)
				this.#value = roundedValue
				this.#moveSlider();
				this.#sliderUserChangedCb();
				e.preventDefault()
			},
		}).on("mousemove", this.#showMouseValue)
			.on("mouseleave", this.#hideMouseValue);


		// Add labels to slider whose values
		// are specified by min, max

		// Get the number of possible values
		const values = this.#forecastSteps.length - 1;
		let day = null;

		// remove all child
		$("#dayLabels").empty();

		const date2tickCount = {}
		for (let i = 0; i <= values; i++) {
			const step = this.#forecastSteps[i];
			const date = this.getDateFromValue(step);
			const dateString = this.#getFastDayMonthDate(date)
			if (dateString in date2tickCount)
				date2tickCount[dateString]++
			else
				date2tickCount[dateString] = 0
		}

		// Position the labels
		if(this.#currentLayer !== "rain") {
			for (let i = 0; i <= values; i++) {
				const step = this.#forecastSteps[i];
				const date = this.getDateFromValue(step);
				const dateString = this.#getFastDayMonthDate(date)

				// same day -> continue
				if(day === dateString) continue;

				day = dateString;

				// create label
				let dateLabel
				if(date2tickCount[dateString] > 100)
					dateLabel = $(`
						<label>
							<div>
								<span class="name">${date2tickCount[dateString] > 100 ? this.#weekdayNames[this.#lang][date.weekday-1] : ""}</span>
								<span class="date">${date2tickCount[dateString] > 160 ? day : ""}</span>
							</div>
						</label>
					`);
				else
					dateLabel = $(`
						<label>
						</label>
					`);

				// change style left
				dateLabel.css('left', `${(i / values * 100)}%`);
				$("#dayLabels").append(dateLabel);
			}
		} else {
			let curDate = DateTime.now()
			let nowRendered = false
			for (let i = 0; i <= values; i++) {
				const step = this.#forecastSteps[i];
				const date = this.getDateFromValue(step);

				let label
				if (i === 0) {
					label = i18next.t("history")
				} else if(!nowRendered && curDate.valueOf() <= date.valueOf()) {
					label = i18next.t("forecast")
					nowRendered = true
				} else {
					continue
				}

				let dateLabel = $(`
					<label>
						<div>
							<span class="name"></span>
							<span class="date">${label}</span>
						</div>
					</label>
				`);

				// change style left
				dateLabel.css('left', `${(i / values * 100)}%`);
				$("#dayLabels").append(dateLabel);
			}
		}
	}

	#stopPlayAtTimestamp = null

	/**
	 * Toggle forecast play
	 * @param {jQuery.Event} e
	 */
	toggleForecastPlay = (e) => {/*
		if(this.#stopPlayAtTimestamp !== null)
			return

		if(!this.#player.played) {
			if(this.#stopCount > 0)
				return false
			$("#playButtonSlider").addClass("played");
			$("#playButton").addClass("played");
			this.#player.played = true;
			this.#playForecast();
			return true
		} else {
			$("#playButtonSlider").removeClass("played");
			$("#playButton").removeClass("played");
			this.#player.played = false;

			let curVal = this.#sliderNode.slider("value")
			let nextMinDiv = this.#currentLayer === "rain" ? 10 : 60
			this.#stopPlayAtTimestamp = curVal + (nextMinDiv - curVal % nextMinDiv)
			return false
		}*/
	}
	/*
	isPlaying = () => this.#player.played
	isLoading = () => this.#stopCount > 0
	*/

	/**
	 * Show value by mouse position on the slider
	 * @param e
	 */
	#showMouseValue = (e) => {

		if(e.target.classList.contains("ui-slider-handle")) {
			this.#hideMouseValue();
			return;
		}

		const sliderNode = this.#sliderNode;
		const stepSize = this.step;
		const min = this.min;
		const stepsCount = this.#activeLayer.getConfigValue("playerTime") - 1;
		const width = this.width;
		const offset = sliderNode.offset();
		const left = e.pageX - offset.left;
		const pos = left / (width + 2); // width + border width

		let step = Math.round(pos * stepsCount);
		if (step < 0) step = 0;
		if (step > stepsCount) step = stepsCount;

		const value = step * stepSize + min;

		const date = this.getDateFromValue(value);
		const hours = date.hour;
		const minutes = date.minute < 10 ? ('0'+date.minute) : date.minute;
		const dateString = this.#getDateStringFromValue(value);

		$("#mousePosition").html(`<div class="slider-label" style="left: ${left}px">${dateString} - ${hours}:${minutes}</div>`);

	}

	/**
	 * Set slider position
	 * @param {number} value
	 */
	#setSliderPosition = (value) => {
		// set slider position
		if(this.#getStepsIdx(value) === -1) {
			this.#sliderNode.slider("value", this.#forecastSteps[0]);
			return true
		} else {
			this.#sliderNode.slider("value", value);
			return false
		}
	}

	/**
	 * Stop autoplay
	 */
	#stopForecastPlay = () => {
		cancelAnimationFrame(this.#player.interval);
	}

	setForceHide = (doForceHide) => {
		if(doForceHide) {
			this.#sliderNode.addClass("hide")
			JQueryNodes.elements.playButtonSlider.addClass("hide")
			JQueryNodes.elements.dayLabels.addClass("hide")
		} else {
			this.#sliderNode.removeClass("hide")
			JQueryNodes.elements.playButtonSlider.removeClass("hide")
			JQueryNodes.elements.dayLabels.removeClass("hide")
		}
	}
	#weekdayNames = {
		"cs": ["pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota", "neděle"],
		"en": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
	}
}