import JQueryNodes from "./JQueryNodes";

export default class CanvasComponent {


	/**
	 * jQuery element
	 */
	#node = JQueryNodes.elements.windyMap;
	get node() {
		return this.#node;
	}

	/**
	 * Get height of node
	 * @returns {Number}
	 */
	get height() {
		return this.#node.height;
	}

	/**
	 * Get width of node
	 * @returns {Number}
	 */
	get width() {
		return this.#node.width;
	}
}