import VectorLayer from "ol/layer/Vector";
import {Fill, Stroke, Style, Text} from "ol/style";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import {Feature, Overlay} from "ol";
import {Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {toStringHDMS} from "ol/coordinate";
import LayerConfig from "./LayerConfig";
import {DateTime} from "luxon";
import i18next from "i18next";
import Dictionary from "./Dictionary";
import { formatDate } from "./Utils";

/**
 * Singleton
 */
export default class PlaceBubbleController {

    /**
     * Constructor
     * @param {MeteosourceApiProtect} apiProtect
     * @param {ApiCache} apiCache
     */
    constructor(apiProtect, apiCache) {
        this.#apiProtect = apiProtect;
        this.#nearestPlaceUrl = import.meta.env.VITE_API_PREFIX + "nearest_place";
        this.#pointUrl = import.meta.env.VITE_API_PREFIX + "point";

        this.#source = new VectorSource({
        });
        this.#layer = new VectorLayer({
            source: this.#source,
            className: "bubble",
		});
        this.#overlay = new Overlay({
            element: document.getElementById("bubble"),
            positioning: 'bottom-center',
            position: fromLonLat([14.418540,50.07365850]),
        });
        
        this.#apiCache = apiCache
    }

    #overlay;

    /**
     * @private
     * @type {MeteosourceApiProtect}
     */
    #apiProtect;

    #apiCache

    /**
     * @private
     * @type {string}
     */
    #nearestPlaceUrl;

    /**
     * @private
     * @type {VectorSource}
     */
    #source;

    /**
     * @private
     * @type {VectorLayer}
     */
    #layer;

    #pointUrl;

    #unit = "metric"
    #lang = "en"

    #getStyle = text => new Style({
        image: new CircleStyle({
            radius: 4,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'red', width: 0.4}),
        }),
        text: new Text({
            font: '16px sans-serif',
            text: text,
            textAlign: "left",
            justify: "left",
            textBaseline: "bottom",
            offsetX: 20,
            offsetY: -20,
            fill: new Fill({color: [255, 255, 255, 1]}),
            backgroundFill: new Fill({color: [0, 0, 0, 0.6]}),
            padding: [6, 6, 6, 6],
        }),
        zIndex: Infinity,
        stroke: new Stroke({color: "red", width: 1.25})
    })

    isBubble = () => this.#lat && this.#lon

    set = async (args) => {
        let doRefresh = false
        if("lat" in args && "lon" in args && (args.lat !== this.#lat || args.lon !== this.#lon)) {
            this.#lat = args.lat
            this.#lon = args.lon
            doRefresh = true
        }
        if("variable" in args && args.variable !== this.#variable) {
            this.#variable = args.variable
            doRefresh = true
        }
        if("date" in args && Math.floor(args.date / (60*60*1000)) !== Math.floor(this.#dateTimestamp / (60*60*1000))) {
            this.#dateTimestamp = args.date
            doRefresh = true
        }
        if("unit" in args && args.unit !== this.#unit) {
            this.#unit = args.unit
            doRefresh = true
        }
        if("lang" in args && args.lang !== this.#lang) {
            this.#lang = args.lang
            doRefresh = true
        }
        if(doRefresh) {
            await this.#refresh()

        }
    }

    clear = async () => {
        await this.set({lat: null, lon: null})
        document.getElementById("bubble").style.display = "none";
    }

    #lat = null
    #lon = null
    #variable = null
    #dateTimestamp = null

    #shownLat = null
    #shownLon = null
    #shownVariable = null

    #apiHourToValue = (pointHourData, airQualityHourData) => {
        switch(this.#variable) {
            case "temperature":
                return pointHourData.temperature
            case "feels_like_temperature":
                return pointHourData.feels_like
            case "clouds":
                return pointHourData.cloud_cover.total
            case "precipitation":
                return pointHourData.precipitation.total
            case "wind_speed":
                return pointHourData.wind.speed
            case "wind_gust":
                return pointHourData.wind.gusts
            case "pressure":
                return pointHourData.pressure
            case "humidity":
                return pointHourData.humidity
            case "air_quality":
                return airQualityHourData.air_quality
            case null:
                return ""
            default:
                console.warn("pointHourToValue: " + this.#variable)
                return "N/A"
        }
    }

    #popoverInitialized = false;

    #setPopover = (lat, lon, title, content) => {
    }

    #clearPopover = () => {
    }

    /**
     * Handle click on the map
     */
    #refresh = async () => {
        if(this.#lat === null || this.#lon === null || this.#dateTimestamp === null) {
            this.#source.clear()
            return
        }

        let lon = this.#lon
        let lat = this.#lat
        let variable = this.#variable

        const feature = new Feature({
            geometry: new Point(fromLonLat([lon, lat])),
        });
        this.#overlay.setPosition(fromLonLat([lon, lat]));
        this.#setPopover(lat, lon, "title", "content");
        if(this.#shownLat !== this.#lat || this.#shownLon !== this.#lon || this.#shownVariable !== this.#variable) {
            this.#source.clear()
            let style = this.#getStyle(i18next.t("loading..."));
            feature.setStyle(style)
            document.getElementById("bubbleTextInner").innerHTML = i18next.t("loading...");
            document.getElementById("bubble").style.display = "block";
            this.#source.addFeature(feature)
        }


        let placeName = toStringHDMS([lon, lat]).replace("N", "N<br>").replace("S", "S<br>");
        let value = "N/A";
        let timeZone = "local";
        try {
            let [resNearestPlace, resPoint, resAirQuality] = await Promise.all([
                fetch(this.#nearestPlaceUrl + "?key=" + this.#apiProtect.getApiKey() + "&lat=" + lat + "&lon=" + lon + "&language=" + this.#lang),
                this.#apiCache.point(lat, lon),
                this.#apiCache.airQuality(lat, lon)
            ])
            let resJson = await resNearestPlace.json()
            let latIsPositive = resJson.lat.charAt(resJson.lat.length-1) === "N"
            let lonIsPositive = resJson.lon.charAt(resJson.lon.length-1) === "E"
            let latNearest = (latIsPositive ? 1 : -1) * resJson.lat.substr(0, resJson.lat.length - 1)
            let lonNearest = (lonIsPositive ? 1 : -1) * resJson.lon.substr(0, resJson.lon.length - 1)
            let dist = Math.sqrt((lonNearest - lon)**2 + (latNearest - lat)**2)
            placeName = (dist < 1) ? resJson.name : placeName

            let resJsonPoint = resPoint[0]
            let resJsonAirQuality = resAirQuality[0]
            timeZone = resPoint[1]

            if(lat !== this.#lat || lon !== this.#lon || variable !== this.#variable)
                return  // user has chosen a different point

            let firstHourTimestamp = new Date(resJsonPoint.hourly.data[0].date + "Z").getTime()
            let numHoursFromFirstHour = (this.#dateTimestamp - firstHourTimestamp) / (1000*60*60)
            numHoursFromFirstHour = Math.round(numHoursFromFirstHour)
            numHoursFromFirstHour = Math.max(0, Math.min(resJsonPoint.hourly.data.length-1, numHoursFromFirstHour))
            let srcHourPoint = resJsonPoint.hourly.data[numHoursFromFirstHour]
            let srcHourAirQuality = resJsonAirQuality.data[numHoursFromFirstHour]
            value = this.#apiHourToValue(srcHourPoint, srcHourAirQuality)
        }
        catch(e) {
            console.error(e)
        }
        this.#source.clear()
        let unit = this.#variable ? LayerConfig.getUnitForType(this.#variable, this.#unit) : "";

        let dateStr;
        if(this.#dateTimestamp !== null) {
            let date = DateTime.fromMillis(this.#dateTimestamp).setZone(timeZone);
            dateStr = formatDate(true, this.#lang, date, timeZone) + " " + date.toFormat(Dictionary.getLuxonTimeFormat(this.#lang));
        } else {
            dateStr = "";
        }
        let style, overlayText;
        if(value === "N/A") {
            style = this.#getStyle(placeName + "\n" + dateStr);
            overlayText = "<span style='font-weight: bold'>" + placeName + "</span><br>" + dateStr;
        } else if(this.#variable === "air_quality") {
            style = this.#getStyle(placeName + "\n" + unit + " " + value + "<br>" + dateStr);
            overlayText = "<span style='font-weight: bold'>" + placeName + "</span><br>" + unit + " " + value + "<br>" + dateStr;
        } else {
            style = this.#getStyle(placeName + "\n" + value + " " + unit + "<br>" + dateStr);
            overlayText = "<span style='font-weight: bold'>" + placeName + "</span><br>" + value + " " + unit + "<br>" + dateStr;
        }

        document.getElementById("bubbleTextInner").innerHTML = overlayText;
        document.getElementById("bubble").style.display = "block";
        feature.setStyle(style)
        this.#source.addFeature(feature)
        this.#shownLat = this.#lat
        this.#shownLon = this.#lon
        this.#shownVariable = this.#variable
    }

    getLayer = () => this.#layer;
    getOverlay = () => this.#overlay;
}