export default class JQueryNodes {

	/**
	 * List of jQuery nodes
	 */
	static elements = {
		labelTitleClass: $("label.title"),
		layerSelect: $("#layerSelect"),
		mapLegend: $("#mapLegend"),
		showHideLayerSelect: $("#showHideLayerSelect"),
		search: $("#search"),
		slider: $("#slider"),
		spanTitleClass: $("span.title"),
		windyMap: $("#windyMap"),
		thTitleClass: $("th.title"),
		placeDetailLabels: $("#placeDetailLabels"),
		dayLabels: $("#dayLabels"),
		playButton: $("#playButton"),
		playButtonSlider: $("#playButtonSlider"),
		placeDetailClose: $("#placeDetailClose"),
		loadingLabel: $("#loadingLabel"),
	}
}