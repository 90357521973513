import ActiveLayer from "./ActiveLayer";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import VectorTileLayer from "ol/layer/VectorTile";
import TileLayer from "ol/layer/Tile";
import LayerConfig from "./LayerConfig";
import JQueryNodes from "./JQueryNodes";
import {TileImage} from "ol/source";
import {DateTime} from "luxon"
import TileGrid from "ol/tilegrid/TileGrid";

import {get} from "ol/proj"
import {getWidth} from "ol/extent"
import * as TileState from "ol/TileState";
import i18next from "i18next";


class URLQueue {

	#priorityURLs = {}
	#numPriorityURLs = () => Object.keys(this.#priorityURLs).length

	waitLoad(url, isPriority) {
		if(isPriority) {
			let prom = Promise.resolve()
			this.#priorityURLs[url] = prom
			return prom
		}
		else {
			return new Promise(resolve => {
				let checkFunc = () => {
					if(this.#numPriorityURLs() === 0) {
						resolve()
					}
					else {
						setTimeout(checkFunc, 300)
					}
				}
				setTimeout(checkFunc, 300)
			})
		}
	}

	loaded(url, isPriority) {
		if(isPriority) {
			delete this.#priorityURLs[url]
		}
	}
}


export default class ForecastLayer {

	/**
	 * Constructor
	 * @param {string} apiKey
	 * @param {ActiveLayer} activeLayer
	 * @param {function} layersPreparedCb
	 * @param {function} stateChangedCb
	 * @param {MeteosourceApiProtect} apiProtect
	 * @param {MapController} mapWrapper
	 */
	constructor(apiKey, activeLayer, layersPreparedCb, stateChangedCb,
				apiProtect, mapWrapper) {
		this.#apiKey = apiKey;
		this.#activeLayer = activeLayer;
		this.#layersPreparedCb = layersPreparedCb;
		this.#stateChangedCb = stateChangedCb;
		this.#apiProtect = apiProtect
		this.#staticFilesPrefix = import.meta.env.BASE_URL + "cmaps/"
		this.#endpointUrl = import.meta.env.VITE_API_PREFIX + "map"
		this.#errorImgPath = import.meta.env.BASE_URL + "error.png"

		this.#apiProtect.onApiKeyChange(key => this.#updateForecast())
		this.#mapWrapper = mapWrapper

		setTimeout(() => {
			setInterval(() => {
				this.#pruneCache()
				this.#updateForecast(true)  // force URL reload
			}, this.#cacheTimeMinutes*60*1000)
		}, 60*1000)
	}

	#mapWrapper;
	#errorImgPath;
	#mvtFormat = new MVT()

	#urlQueue = new URLQueue()
	#legendShown = true
	#lang = "en"
	#unit = "metric"

	/**
	 * @type {string}
	 */
	#staticFilesPrefix;

	#fetchAbortController = new AbortController()

	/**
	 * @type {string}
	 */
	#endpointUrl;

	/**
	 * Stores active layer
	 * @type {ActiveLayer}
	 */
	#activeLayer;

	/**
	 * @private
	 * @type {MeteosourceApiProtect}
	 */
	#apiProtect;

	/**
	 * @private
	 * Api key for www.meteocentrum.cz
	 * @type {string}
	 */
	#apiKey;

	/**
	 * Stores forecsat layer for the vectors -- one part of animation
	 * @type {VectorTileLayer}
	 */
	#forecastLayerA;

	/**
	 * Stores forecsat layer for the vectors -- second part of animation
	 * @type {VectorTileLayer}
	 */
	#forecastLayerB;

	/**
	 * Stores forecsat layer for WebGl
	 * @type {VectorTileLayer}
	 */
	#forecastLayerWebGlA;
	#forecastLayerWebGlB;
	#forecastLayerWebGlC;

	#forecastLayerWebGlBefore = null;
	#forecastLayerWebGlAfter = null;
	#forecastLayerWebGlHidden = null;

	/**
	 * Stores actual visible layer type
	 * @type {string} - LayerConfig.LAYER_TYPE_RASTER | LayerConfig.LAYER_TYPE_VECTOR
	 */
	#layerType

	/**
	 * Stores actual visible layer name
	 * @type {string}
	 */
	#layerName
	#prevLayerName

	/**
	 * Function when is switch new forecast
	 * @type {function}
	 */
	#layersPreparedCb;
	#stateChangedCb;

	#date = null;
	#prevDate = null

	init = async () => {
		await this.#apiProtect.init()
	}

	setUnit = unit => {
		this.#unit = unit
	}
	setLang = lang => {
		this.#lang = lang
	}

	abortDownloads = () => {
		if(this.#fetchAbortController) {
			this.#fetchAbortController.abort()
			this.#fetchAbortController = new AbortController()
		}
	}

	/**
	 * Return url for forecast
	 * @returns {string}
	 */
	#getForecastUrl = (layerName) => {
	    if(this.#activeLayer.type === "defaultLayer" || this.#activeLayer.type === null)
	    	return ""

		// we add a random string to the end of the URL (f.e. "#1234") to ensure that tileLoadFunction is run
		// every time we run #updateForecast (even when the datetime is the same)
		const randomPostfix = Math.floor(Math.random() * 1000000)

		if(layerName !== "webglA" && layerName !== "webglB" && layerName !== "webglBefore" && layerName !== "webglAfter" && layerName !== "webglHidden") {
			if(this.#activeLayer.type !== "rain" && this.#activeLayer.type !== "significant_weather")
				return ""
			let date = this.#date

			if(this.#activeLayer.type === "rain") {
				let isReversed = (Math.floor(date.minute / 10) % 2 === 0)
				if ((layerName === "A" && !isReversed) || (layerName === "B" && isReversed)) {
					date = date.minus({"minutes": date.minute % 10}).startOf("minute")
				} else if ((layerName === "B" && !isReversed) || (layerName === "A" && isReversed)) {
					date = date.minus({"minutes": date.minute % 10}).plus({"minutes": 10}).startOf("minute")
				} else {
					throw "strange layer name " + layerName
				}
			}
			else if(this.#activeLayer.type === "significant_weather") {
				let isReversed = date.hour % 2 === 0
				if ((layerName === "A" && !isReversed) || (layerName === "B" && isReversed)) {
					date = date.startOf("hour")
				} else if ((layerName === "B" && !isReversed) || (layerName === "A" && isReversed)) {
					date = date.startOf("hour").plus({hours: 1})
				} else {
					throw "strange layer name " + layerName
				}
			}

			const dateStr = date.toFormat("yyyy-LL-dd") + "T" + date.toFormat("HH:mm")
			const key = this.#apiProtect.getApiKey()

			return `${this.#endpointUrl}?tile_x={x}&tile_y={y}&tile_zoom={z}&key=${key}&datetime=${dateStr}&variable=${this.#activeLayer.type}&format=pbf`
		} else {
			if(this.#activeLayer.type === "rain" || this.#activeLayer.type === "significant_weather")
				return ""

			let date = this.#date
			const key = this.#apiProtect.getApiKey()

			let isReversed = date.hour % 2 === 0
			if (layerName === "webglBefore") {
				date = date.startOf("hour")
			} else if (layerName === "webglAfter") {
				date = date.startOf("hour").plus({hours: 1})
			} else if (layerName === "webglHidden") {
				date = date.startOf("hour").plus({hours: 2})
			} else {
				throw "strange layer name " + layerName
			}
			const dateStr = date.toFormat("yyyy-LL-dd") + "T" + date.toFormat("HH:mm")
			return `${this.#endpointUrl}?tile_x={x}&tile_y={y}&tile_zoom={z}&key=${key}&datetime=${dateStr}&variable=${this.#activeLayer.type}_noalpha`
		}
	}

	#getSource = (layerName) => {
		var projExtent = get('EPSG:3857').getExtent();
		var startResolution = getWidth(projExtent) / 256;
		var resolutions = new Array(22);
		for (var i = 0, ii = resolutions.length; i < ii; ++i) {
			resolutions[i] = startResolution / Math.pow(2, i);
		}

		var tileGrid = new TileGrid({
			extent: projExtent,
			resolutions: resolutions.slice(2),  // 0 for 256, 1 for 512, 2 for 1024...
			tileSize: [1024, 1024]
		});
		if(layerName === "webglA" || layerName === "webglB" || layerName === "webglBefore" || layerName === "webglAfter" || layerName === "webglHidden") {
			return new TileImage({
				tileGrid: tileGrid,
				tileLoadFunction: (imageTile, urlStr) => this.#tileLoadFunction(layerName, imageTile, urlStr),
				url: this.#getForecastUrl(layerName),
				crossOrigin: "anonymous"
			})
		} else if (layerName === "A" || layerName === "B") {
			return new VectorTileSource({
				format: new MVT(),
				tileGrid: tileGrid,
				tileLoadFunction: (imageTile, urlStr) => {
					this.#tileLoadFunction(layerName, imageTile, urlStr)
				},
				url: this.#getForecastUrl(layerName),
				transition: 300,
			});
		} else {
			throw "wrong layer name " + layerName
		}
    }

    #updateForecastIsRunning = false

	/**
	 * Switch forecast layer source, for update forecast
	 */
	#updateForecast = (forceReloadUrl = false) => {
		if(this.#updateForecastIsRunning) {
			return
		}
		try {
			this.#updateForecastIsRunning = true

			let layerInterval = this.#activeLayer.type === "rain" ? 10*60*1000 : 60*60*1000
			if (this.#forecastLayerA && this.#forecastLayerA.getSource()) {
				if (this.#activeLayer.type === "rain" || this.#activeLayer.type === "significant_weather") {
					let fastProcess = !forceReloadUrl && this.#prevDate && (Math.floor(this.#date.toMillis() / layerInterval) === Math.floor(this.#prevDate.toMillis() / layerInterval)) && this.#layerName === this.#prevLayerName
                    if(!fastProcess) {
						let srcA = this.#forecastLayerA.getSource()
						let urlA = this.#getForecastUrl("A")
						srcA.setUrl(urlA)
						let srcB = this.#forecastLayerB.getSource()
						let urlB = this.#getForecastUrl("B")
						srcB.setUrl(urlB)
					}

					let date = this.#date
					let opacityA, opacityB
					if(this.#activeLayer.type === "rain") {
						opacityA = (date.minute % 10) < 5 ? 1 : (1 - ((date.minute % 10) - 5) * 0.2)
						opacityB = (date.minute % 10) > 5 ? 1 : (((date.minute % 10)) * 0.2)
						if (Math.floor(date.minute / 10) % 2 === 0)
							[opacityA, opacityB] = [opacityB, opacityA]
					} else if(this.#activeLayer.type === "significant_weather") {
						let step = Math.floor(date.minute / 5)
						opacityA = [0.8, 0.76, 0.73333333, 0.66666667, 0.6, 0.53333333, 0.46666667, 0.4, 0.33333333, 0.26666667, 0.13333333, 0.06666667][step]
						opacityB = (opacityA > 0.999) ? 0 : ((-1 + opacityA + 0.2) / (opacityA - 1))
						// opacityA = opacityB = 0.5
						if (date.hour % 2 === 0) {
							[opacityA, opacityB] = [opacityB, opacityA]
						}
					} else {
						throw "unknown type " + this.#activeLayer.type
					}
					this.#forecastLayerA.setOpacity(opacityA)
					this.#forecastLayerB.setOpacity(opacityB)

					if(!fastProcess) {
						this.#forecastLayerWebGlA.setOpacity(0)
						this.#forecastLayerWebGlB.setOpacity(0)
					}
				} else {
					let fastProcess = !forceReloadUrl && this.#prevDate && (Math.floor(this.#date.toMillis() / layerInterval) === Math.floor(this.#prevDate.toMillis() / layerInterval)) && this.#layerName === this.#prevLayerName
                    if(this.#forecastLayerWebGlBefore === null) {
                    	this.#forecastLayerWebGlBefore = this.#forecastLayerWebGlA
						this.#forecastLayerWebGlAfter = this.#forecastLayerWebGlB
						this.#forecastLayerWebGlHidden = this.#forecastLayerWebGlC
					}
					if(!fastProcess) {
						let urlBefore = this.#getForecastUrl("webglBefore")
						let urlAfter = this.#getForecastUrl("webglAfter")
						let urlHidden = this.#getForecastUrl("webglHidden")

						if(this.#date.hour % 3 === 0) {
							this.#forecastLayerWebGlBefore = this.#forecastLayerWebGlA
							this.#forecastLayerWebGlAfter = this.#forecastLayerWebGlB
							this.#forecastLayerWebGlHidden = this.#forecastLayerWebGlC
						} else if(this.#date.hour % 3 === 1) {
							this.#forecastLayerWebGlBefore = this.#forecastLayerWebGlB
							this.#forecastLayerWebGlAfter = this.#forecastLayerWebGlC
							this.#forecastLayerWebGlHidden = this.#forecastLayerWebGlA
						} else {
							this.#forecastLayerWebGlBefore = this.#forecastLayerWebGlC
							this.#forecastLayerWebGlAfter = this.#forecastLayerWebGlA
							this.#forecastLayerWebGlHidden = this.#forecastLayerWebGlB
						}

						this.#forecastLayerWebGlBefore.getSource().setUrl(urlBefore)
						this.#forecastLayerWebGlAfter.getSource().setUrl(urlAfter)
						this.#forecastLayerWebGlHidden.getSource().setUrl(urlHidden)
					}
					let date = this.#date
					let step = Math.floor(date.minute / 5)

					// we want to ensure that opacityA * opacityB = const and that the transition is smooth
					let opacityBefore = [0.8, 0.76, 0.73333333, 0.66666667, 0.6, 0.53333333, 0.46666667, 0.4, 0.33333333, 0.26666667, 0.13333333, 0.06666667][step]
					let opacityAfter = (opacityBefore > 0.999) ? 0 : ((-1 + opacityBefore + 0.2) / (opacityBefore - 1))
					this.#forecastLayerWebGlBefore.setOpacity(opacityBefore)
					this.#forecastLayerWebGlAfter.setOpacity(opacityAfter)
					this.#forecastLayerWebGlHidden.setOpacity(0)
					if(!fastProcess) {
						this.#forecastLayerA.setOpacity(0)
						this.#forecastLayerB.setOpacity(0)
					}
				}

			} else if (this.#forecastLayerA && !this.#forecastLayerA.getSource()) {
				this.#forecastLayerA?.setSource(this.#getSource("A"));
				this.#forecastLayerB?.setSource(this.#getSource("B"));
				this.#forecastLayerWebGlA?.setSource(this.#getSource("webglBefore"));
				this.#forecastLayerWebGlB?.setSource(this.#getSource("webglAfter"));
				this.#forecastLayerWebGlC?.setSource(this.#getSource("webglHidden"));
				this.#forecastLayerWebGlBefore = this.#forecastLayerWebGlA
				this.#forecastLayerWebGlAfter = this.#forecastLayerWebGlB
				this.#forecastLayerWebGlHidden = this.#forecastLayerWebGlC
			}
		}
		finally {
			this.#updateForecastIsRunning = false
		}
	}

	clearTiles = () => {
		this.#forecastLayerWebGlBefore?.getSource()?.clear()
		this.#forecastLayerWebGlAfter?.getSource()?.clear()
		this.#forecastLayerWebGlHidden?.getSource()?.clear()

	}

	/**
	 * Prepare forecast layer
	 */
	prepareForecast = (args) => {
		const layerType = this.#activeLayer.getConfigValue(ActiveLayer.PARAM_LAYER_TYPE);
		this.#prevLayerName = this.#layerName
		this.#layerName = this.#activeLayer.type;
		if(args.date) {
			this.#prevDate = this.#date
			this.#date = DateTime.fromMillis(args.date).setZone("UTC")
		}
		if(args.lang) {
			this.#lang = args.lang
		}
		if(args.unit) {
			this.#unit = args.unit
		}

		if(this.#prevLayerName === this.#layerName) {
			// no new layer, just update source
			this.#updateForecast();
		} else {
			this.#layerName = this.#activeLayer.type;
			if(layerType === this.#layerType && layerType === LayerConfig.LAYER_TYPE_RASTER) {

			    // remove cache of the webgl layer itself (not the source) -- otherwise we might see tiles
				// from the previous variable selected
				// https://github.com/openlayers/openlayers/issues/13051

				//this.#forecastLayerWebGlA?.getRenderer().tileTextureCache_.clear()
				//this.#forecastLayerWebGlB?.getRenderer().tileTextureCache_.clear()
				this.#updateForecast();
			} else if(layerType === LayerConfig.LAYER_TYPE_VECTOR) {
				// prepare vector layer
				this.#prepareVectorForecast();
			} else {
				// prepare raster layer
				this.#prepareRasterForecast();
			}
		}
	}

	/**
	 * Prepare raster forecast layer
	 */
	#prepareRasterForecast = () => {
		this.#layerType = LayerConfig.LAYER_TYPE_RASTER;
		if(this.#forecastLayerA) {
			this.#forecastLayerA.setSource(this.#getSource("A"))
			this.#forecastLayerB.setSource(this.#getSource("B"))
			this.#forecastLayerWebGlBefore.setSource(this.#getSource("webglBefore"))
			this.#forecastLayerWebGlAfter.setSource(this.#getSource("webglAfter"))
			this.#forecastLayerWebGlHidden.setSource(this.#getSource("webglHidden"))
		} else {
			this.#forecastLayerA = new VectorTileLayer({
				opacity: 50,
				zIndex: 10000000000,
				// projection: "EPSG:3857",
				source: this.#getSource("A"),
				// renderMode: "vector",
				style:(feature) => {
                    return [
                         new Style({
							  zIndex: 10000000000,
                              fill: new Fill({
                                   color: this.#activeLayer.getColors(feature),
                              }),
                         })
                    ];
               },
			})
			this.#forecastLayerB = new VectorTileLayer({
				opacity: 80,
				zIndex: 10000000000,
				// projection: "EPSG:3857",
				source: this.#getSource("B"),
				// renderMode: "vector",
				style:(feature) => {
					return [
						new Style({
							zIndex: 1,
							fill: new Fill({
								color: this.#activeLayer.getColors(feature),
							}),
						})
					];
				},
			});
			this.#forecastLayerWebGlA = new TileLayer({
				opacity: 30,
				zIndex: 10000000000,
				// projection: "EPSG:3857",
				source: this.#getSource("webglBefore"),
				// maxZoom: 10,
				// tileGrid: new TileGrid({tileSize: [512, 512]}),
			});
			this.#forecastLayerWebGlB = new TileLayer({
				opacity: 30,
				zIndex: 10000000000,
				// projection: "EPSG:3857",
				source: this.#getSource("webglAfter"),
				// maxZoom: 10,
				// tileGrid: new TileGrid({tileSize: [512, 512]}),
			});
			this.#forecastLayerWebGlC = new TileLayer({
				opacity: 30,
				zIndex: 10000000000,
				// projection: "EPSG:3857",
				source: this.#getSource("webglHidden"),
				// maxZoom: 10,
				// tileGrid: new TileGrid({tileSize: [512, 512]}),
			});
			this.#layersPreparedCb(this.#forecastLayerA, this.#forecastLayerB, this.#forecastLayerWebGlA, this.#forecastLayerWebGlB, this.#forecastLayerWebGlC);
		}
	}

	#tileKey2src = {}
	#tileKey2features = {}
	#tileKey2promise = {}

	#firstGenerationStarts = DateTime.now()
	#cacheTimeMinutes = 1

	#getGenerationNumber = () => Math.floor(Math.max(0, DateTime.now() - this.#firstGenerationStarts) / (1000*60*this.#cacheTimeMinutes))

	#pruneCache = () => {
		let oldGenerationNum = this.#getGenerationNumber() - 1
		if(oldGenerationNum < 0)
			return

		let keysToRemove = []
		for(let key of Object.keys(this.#tileKey2src)) {
			let keyParts = key.split(",")
			let generationNum = keyParts[keyParts.length-1]
			if(generationNum <= oldGenerationNum)
				keysToRemove.push(key)
		}
		for(let key of keysToRemove)
			delete this.#tileKey2src[key]

		keysToRemove = []
		for(let key of Object.keys(this.#tileKey2promise)) {
			let keyParts = key.split(",")
			let generationNum = keyParts[keyParts.length-1]
			if(generationNum <= oldGenerationNum)
				keysToRemove.push(key)
		}
		for(let key of keysToRemove)
			delete this.#tileKey2promise[key]

	}

	#getTileKey = (url) => {

		// to ensure tiles are reloaded once in #cacheTimeMinutes minutes, we also add a generation number to the
		// tile key. The generation number starts with 0 and increases every #cacheTimeMinutes minutes. Which means
		// that after each increase, our cache is "invalidated"
		let generationNum = this.#getGenerationNumber()

		return url.searchParams.get("datetime") + "," + url.searchParams.get("variable") + "," +
			url.searchParams.get("tile_x") + "," + url.searchParams.get("tile_y") + "," + url.searchParams.get("tile_zoom") +
			"," + generationNum
	}

	#multiFetch = async (url, params) => {
		let res
		for(let i=0; i<3; i++) {
			try {
				res = await fetch(url, params)
				if(res.ok)
					return res
				else
					console.error("status code for " + url + " is " + res.status)
			}
			catch(e) {
				console.error(e)
				if("name" in e && e.name === "AbortError")
					return res
			}
			await new Promise(resolve => setTimeout(resolve, 1000))
		}
		return res
	}

	#tileLoadFunctionImage = (layerName, tileKey, imageTile, url, extent, projection, fetchAbortSignal) => {
		let isVector = (layerName === "A") || (layerName === "B")
		if(!isVector && tileKey in this.#tileKey2src)
			return Promise.resolve(this.#tileKey2src[tileKey])
		if(!isVector && tileKey in this.#tileKey2promise)
			return this.#tileKey2promise[tileKey]

		if(isVector && tileKey in this.#tileKey2features)
			return Promise.resolve(this.#tileKey2features[tileKey])
		if(isVector && tileKey in this.#tileKey2features)
			return this.#tileKey2features[tileKey]

		let promise = async () => {
			try {
				await this.#urlQueue.waitLoad(url.toString(), !!imageTile)
				if(!isVector) {
					await this.#multiFetch(url.toString(), {signal: fetchAbortSignal})  // load to the browser cache


					let image = new Image()
					let isLoadedResolve, isLoadedReject
					let isLoaded = new Promise((resolve, reject) => {
						isLoadedResolve = resolve
						isLoadedReject = reject
					})
					image.crossOrigin = "anonymous"
					image.onload = isLoadedResolve
					image.onerror = isLoadedReject
					image.src = url.toString()

					await isLoaded

                    /* The following code was used during the times when there were only two WebGl layers and without
                       converting the image to BLOB, the tile would not show immediately. Because of animation problems,
                       we had to add a third layer. Thus, preloading to BLOB is not necessary and the loading is much
                       faster. Keeping the code here just for the idea, might get handy in the future when something
                       else breaks...?
                     */
					/*
						let canvas = document.createElement("canvas")
						canvas.width = image.width
						canvas.height = image.height
						let context = canvas.getContext("2d")
						context.drawImage(image, 0, 0)

						let blob = await new Promise((resolve, reject) => canvas.toBlob(resolve))
						this.#tileKey2src[tileKey] = blob
					*/

					this.#tileKey2src[tileKey] = url.toString()
					return this.#tileKey2src[tileKey]
				} else {
					let response = await this.#multiFetch(url.toString(), {signal: fetchAbortSignal})
					let data = await response.arrayBuffer()
					const features = this.#mvtFormat.readFeatures(data, {
						extent: extent,
						featureProjection: projection
					})
					this.#tileKey2features[tileKey] = features
					return this.#tileKey2features[tileKey]
				}
			}
			finally {
				this.#urlQueue.loaded(url.toString(), !!imageTile)
			}

			// uncomment to simulate slow network
			/* let isTimeout = new Promise((resolve, reject) => {
				setTimeout(resolve, 5000)
			})
			await isTimeout */

		}
		this.#tileKey2promise[tileKey] = promise()
		return this.#tileKey2promise[tileKey]
	}

	#tileLoadFunctionAsync = async (layerName, tileKey, imageTile, url, destImage, extent, resolution, projection, fetchAbortSignal) => {
		try {
			let isVector = (layerName === "A") || (layerName === "B")
            if(!isVector)
				imageTile?.setState(TileState.LOADING)
			let srcOrFeatures = await this.#tileLoadFunctionImage(layerName, tileKey, imageTile, url, extent, projection, fetchAbortSignal)

			if(!isVector && destImage)  // tady nastavit, aby se to kdyztak nahralo do tilu
				destImage.src = srcOrFeatures
			else if(isVector && imageTile) {
				imageTile.setFeatures(srcOrFeatures);
			}
			if(!isVector)
				imageTile?.setState(TileState.LOADED)
		}
		catch(e) {
			if(destImage)
				destImage.src = this.#errorImgPath
			if(!isVector)
				imageTile?.setState(TileState.ERROR)
			console.error("cannot load tile", tileKey)
			console.error(e.stack)
		}
	}

	#tileLoadFunction = (layerName, imageTile, urlStr) => {
		let isVector = (layerName === "A") || (layerName === "B")
        let fetchAbortSignal = this.#fetchAbortController.signal

	    if(!urlStr)
	    	return
		let url = new URL(urlStr)
		url.hash = ""  // to remove the random postfix

		let func = (extent, resolution, projection) => {

			let image = isVector ? null : imageTile.getImage()
			let tileKey = this.#getTileKey(url)

			let pauseSliderPromises = []
			let imageSaved = (!isVector && (tileKey in this.#tileKey2src)) || (isVector && (tileKey in this.#tileKey2features))
			if (imageSaved) {
				if (isVector)
					imageTile.setFeatures(this.#tileKey2features[tileKey]);
				else
					image.src = this.#tileKey2src[tileKey]
			} else {
				let load = this.#tileLoadFunctionAsync(layerName, tileKey, imageTile, urlStr, image, extent, resolution, projection, fetchAbortSignal)
				pauseSliderPromises.push(load)
			}

			(async () => {
				if(pauseSliderPromises.length > 0) {
					try {
						this.#loadingStarted()
						await Promise.allSettled(pauseSliderPromises)
					} finally {
						this.#loadingEnded()
					}
				}

				try {
					this.#preloadingStarted()

					let preloadPromises = []
					for(let i=1; i<=4; i++) {
						let url2 = new URL(url)
						let datetime = DateTime.fromISO(url.searchParams.get("datetime") + "Z").setZone("UTC")
						let hours = (this.#activeLayer.type === "rain") ? 0 : i
						let minutes = (this.#activeLayer.type === "rain") ? (i*10) : 0
						datetime = datetime.plus({hours: hours, minutes: minutes})
						url2.searchParams.set("datetime", datetime.toISO().substr(0, 16))
						let tileKey2 = this.#getTileKey(url2)
						let load = this.#tileLoadFunctionAsync(layerName, tileKey2, null, url2.toString(), null, extent, resolution, projection, fetchAbortSignal)
						preloadPromises.push(load)
					}

					await Promise.allSettled(preloadPromises)
				}
				finally {
					this.#preloadingEnded()
				}
			})()
		}
	    if(isVector)
	    	imageTile.setLoader(func)
		else
			func(null, null, null)
	}

	#numTilesLoading = 0
	#numTilesPreloading = 0

	#loadingStarted = () => {
		this.#numTilesLoading++
		if(this.#numTilesLoading === 1)
			this.#stateMaybeChanged();
	}
	#loadingEnded = () => {
		this.#numTilesLoading--
		this.#numTilesLoading = Math.max(0, this.#numTilesLoading)
		if(this.#numTilesLoading === 0)
			this.#stateMaybeChanged();
	}

	#preloadingStarted = () => {
		this.#numTilesPreloading++
		if(this.#numTilesPreloading === 1)
			this.#stateMaybeChanged();
	}
	#preloadingEnded = () => {
		this.#numTilesPreloading--
		this.#numTilesPreloading = Math.max(0, this.#numTilesPreloading)
		if(this.#numTilesPreloading === 0)
			this.#stateMaybeChanged();
	}

	#currentState = "loaded";

	#stateMaybeChanged = () => {
		let newState = this.#numTilesLoading > 0 ? "loading" : this.#numTilesPreloading > 0 ? "preloading" : "loaded";
		if(this.#currentState !== newState) {
			this.#currentState = newState;
			this.#stateChangedCb(newState);
		}
	}

	getState = () => this.#currentState;

	/**
	 * Prepare vector forecast layer
	 */
	#prepareVectorForecast = () => {
	    this.#prepareRasterForecast();
	}

	/**
	 * Draw legend
	 */
	renderLegend = () => {
		if(this.#activeLayer.type === null)
			return
		const layerSelectNode = JQueryNodes.elements.layerSelect;
		const showHideLayerSelectNode = JQueryNodes.elements.showHideLayerSelect;
		const mapLegendNode = JQueryNodes.elements.mapLegend;
		// reset active class

        let activeLayerButton = layerSelectNode.find(`button[name='${this.#activeLayer.type}']`)
		let isLayerFromSelect = activeLayerButton.length === 0
		if(isLayerFromSelect) {
			layerSelectNode.find("button").removeClass("active");
			$("#extraLayersSelect").addClass("active")
			$("#extraLayersSelect option").removeClass("active")
			$("#extraLayersSelect option").removeClass("nonactive")
			$("#extraLayersSelect option[value='"+this.#activeLayer.type+"']").addClass("active")
			$("#extraLayersSelect option[value!='"+this.#activeLayer.type+"']").addClass("nonactive")
			$("#extraLayersSelect").val(this.#activeLayer.type)
		} else if (this.#activeLayer.type) {
			layerSelectNode.find("button").removeClass("active");
			activeLayerButton.addClass("active");
			$("#extraLayersSelect").removeClass("active")
			$("#extraLayersSelect option").removeClass("active")
			$("#extraLayersSelect option").removeClass("nonactive")
			$("#extraLayersSelect").val("placeholder")
		}

		let selectedButtonText = $("#layerSelect button.active .title")
		let showHideText
		if(selectedButtonText.length > 0)
			showHideText = selectedButtonText.text()
		else
			showHideText = $("#extraLayersSelect option.active").text()

		showHideLayerSelectNode.find("button[name='showHide'] .title").text(showHideText)
		showHideLayerSelectNode.removeClass("opened");
		layerSelectNode.removeClass("opened");

        let lng = "";
        if(this.#activeLayer.type === "significant_weather" && this.#lang === "cs") {
            lng = `_${this.#lang}`
        }

		if(this.#unit !== "metric") {
			mapLegendNode.find("img").attr("src", `${this.#staticFilesPrefix}${this.#activeLayer.type}_${this.#unit}${lng}.png`);
			mapLegendNode.find(".title").text(this.#activeLayer.getLegendTitle(this.#unit));
		} else {
			mapLegendNode.find("img").attr("src", `${this.#staticFilesPrefix}${this.#activeLayer.type}_metric${lng}.png`);
		}
		mapLegendNode.find(".title").text(this.#activeLayer.getLegendTitle(this.#unit));
	}

	setShowLegend = doShow => {
		//$("#showHideLegend .title").html(i18next.t("legend"))
		if(doShow === undefined)
			doShow = !this.#legendShown
		if(!this.#activeLayer.type)
			doShow = false
		if(!doShow) {
			$("#mapLegend").addClass("hide")
			$("#mapLegendTitle").addClass("hide")
			$("#mapLegendLegend").addClass("hide")
			$("#showHideLegend").removeClass("opened")
			this.#legendShown = false
		} else {
			$("#mapLegend").removeClass("hide")
			$("#mapLegendTitle").removeClass("hide")
			$("#mapLegendLegend").removeClass("hide")
			$("#showHideLegend").addClass("opened")
			$("#showHide").html(i18next.t("hide"))
			this.#legendShown = true
		}
	}
}