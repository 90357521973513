import i18next from "i18next";
import JQueryNodes from "./JQueryNodes";

export default class Dictionary {

	/**
	 * Available languages
	 * @type {string[]}
	 */
	#languages = ["cs", "en"]

	/**
	 * Init dictionary
	 */
    init = (lng="en") => {
        i18next
            .init({
                lng: lng,
                debug: true,
                resources: {
                    cs: {
                        translation: {
                            "temperature": "Teplota",
                            "feels like temperature": "Pocitová teplota",
                            "clouds": "Oblačnost",
                            "precipitation": "Srážky",
                            "wind speed": "Rychlost větru",
                            "wind gust": "Nárazy větru",
                            "pressure": "Tlak",
                            "humidity": "Vlhkost",
                            "air quality": "Kvalita ovzduší",
                            "significant weather": "Významné počasí",
                            "temperature trial": "Pokus - teplota",
                            "precipitation trial": "Pokus - srážky",
                            "rain trial": "Radar",
                            "rain_highres": "rain_highres",
                            "streamlines": "Proudění",
                            "menu": "menu",
                            "Monday": "Pondělí",
                            "Tuesday": "Úterý",
                            "Wednesday": "Středa",
                            "Thursday": "Čtvrtek",
                            "Friday": "Pátek",
                            "Saturday": "Sobota",
                            "Sunday": "Neděle",
                            "day": "den",
                            "hour": "hodina",
                            "wind": "vítr",
                            "more layers": "Další vrstvy...",
                            "less layers": "Méně vrstev",
                            "tomorrow": "zítra",
                            "today": "dnes",
                            "show": "zobrazit",
                            "hide": "skrýt",
                            "history": "Historie",
                            "forecast": "Předpověď",
                            "date": "datum",
                            "page must be reloaded": "Musíme znovunačíst stránku, prosím čekejte...",
                            "loading...": "načítání dat...",
                            "legend": "Legenda",
                            "weather": "počasí",
                        }
                    },
                    en: {
                        translation: {
                            "temperature": "Temperature",
                            "feels like temperature": "Feels like temperature",
                            "clouds": "Clouds",
                            "precipitation": "Precipitation",
                            "wind speed": "Wind speed",
                            "wind gust": "Wind gust",
                            "pressure": "Pressure",
                            "humidity": "Humidity",
                            "air quality": "Air Quality",
                            "significant weather": "Significant weather",
                            "temperature trial": "Temperature trial",
                            "precipitation trial": "Precipitation trial",
                            "rain trial": "Radar",
                            "rain_highres": "rain_highres",
                            "streamlines": "Streamlines",
                            "menu": "menu",
                            "Monday": "Monday",
                            "Tuesday": "Tuesday",
                            "Wednesday": "Wednesday",
                            "Thursday": "Thursday",
                            "Friday": "Friday",
                            "Saturday": "Saturday",
                            "Sunday": "Sunday",
                            "day": "day",
                            "hour": "hour",
                            "wind": "wind",
                            "more layers": "More layers...",
                            "less layers": "Less layers",
                            "tomorrow": "Tomorrow",
                            "today": "Today",
                            "show": "show",
                            "hide": "hide",
                            "history": "History",
                            "forecast": "Forecast",
                            "date": "date",
                            "page must be reloaded": "We have to reload the page, please wait...",
                            "loading...": "loading...",
                            "legend": "Legend",
                            "weather": "weather",
                        }
                    }
                } })
            .then(this.#applyTranslation);
    }

	/**
	 * Return true when lng is in this.#languages
	 * @param {string} lng
	 * @returns {boolean}
	 */
	isLanguageAvailable = (lng) => {
		return in_array(lng, this.#languages);
	}

    static getLuxonTimeFormat = lng => {
        if (lng === "cs") {
            return "H:mm";
        }
        else if(lng === "en") {
            return "H:mm";
        }
        else {
            console.warn("getLuxonDateTimeFormat: unknown language " + lng)
            return "H:mm";
        }
    }

    static getLuxonDateTimeFormat = lng => {
        if (lng === "cs") {
            return "d. L. H:mm";
        }
        else if(lng === "en") {
            return "yyyy-LL-dd H:mm";
        }
        else {
            console.warn("getLuxonDateTimeFormat: unknown language " + lng)
            return "yyyy-LL-dd H:mm";
        }
    }

	/**
	 * Apply translations (after init())
	 */
    #applyTranslation = () => {

		// replace layer selectors titles
        JQueryNodes.elements.spanTitleClass.each(function () {
            $(this).text(i18next.t($(this).text()));
        });

		// replace
		const labelTitle = JQueryNodes.elements.labelTitleClass;
	    labelTitle.text(i18next.t(labelTitle.text()));

        // replace place detail th title
        JQueryNodes.elements.thTitleClass.each(function () {
            $(this).text(i18next.t($(this).text()));
        });

        $("#extraLayersSelect option").each(function() {
            $(this).text(i18next.t($(this).text()));
        })
    }
}