export default class Announcer {

    constructor() {
        this.#elAnnouncer = $("#announcer")
        this.#elAnnouncerText = $("#announcerTextContent")
    }

    #elAnnouncer
    #elAnnouncerText

    hide() {
        this.#elAnnouncer.hide()
        this.#elAnnouncerText.hide()
    }

    setText(text) {
        this.#elAnnouncerText.html(text)
        this.#elAnnouncer.show()
        this.#elAnnouncerText.show()

    }
}