import MapController from "./MapController";
import Dictionary from "./Dictionary";
import CanvasComponent from "./CanvasComponent";
import Slider from "./Slider";
import ActiveLayer from "./ActiveLayer";
import WindyController from "./WindyController";
import ForecastLayer from "./ForecastLayer";
import LayerConfig from "./LayerConfig";
import JQueryNodes from "./JQueryNodes";
import {toLonLat, transform} from "ol/proj";
import SearchController from "./SearchController";
import PlaceDetail from "./PlaceDetail";
import MeteosourceApiProtect from "./MeteosourceApiProtect"
import PlaceBubbleController from "./PlaceBubbleController";
import i18next from "i18next"
import Player from "./Player";
import Announcer from "./Announcer";
import ApiCache from "./ApiCache";
import { parseLatLon } from "./Utils";


export default class App {

	/**
	 * Constructor
	 */
	constructor() {
		// objects
		this.#activeLayer = new ActiveLayer(LayerConfig.TYPE_DEFAULT, this.#switchForecast)
		this.#dictionary = new Dictionary();
		this.#apiProtect = new MeteosourceApiProtect(import.meta.env.VITE_API_PREFIX)
        this.#apiCache = new ApiCache(this.#apiProtect)
		this.#slider = new Slider(this.#activeLayer, this.#sliderChanged, this.#sliderUserChanged, this.#apiProtect, this.#apiCache);
		this.#placeBubbleController = new PlaceBubbleController(this.#apiProtect, this.#apiCache)
		this.#mapWrapper = new MapController(this.#mapMoveChanged, this.#editUrlParams,
			this.#apiProtect, this.#placeBubbleController);
		this.#forecast = new ForecastLayer(this.#apiKey, this.#activeLayer,
			this.#mapWrapper.switchForecastLayer, this.#loadingStateChanged, this.#apiProtect, this.#mapWrapper);
		this.#searchController = new SearchController(this.#mapWrapper, this.#apiProtect, this.#dictionary.isLanguageAvailable, this.#searchLocationChosen);
		this.#placeDetailController = new PlaceDetail(this.#mapWrapper, this.#activeLayer, this.#dictionary.isLanguageAvailable,
			this.#apiProtect, this.#placeDetailDateSelected, this.#placeDetailCloseClicked, this.#apiCache);
		this.#player = new Player(this.#playerStateChanged, this.#playerFrame)
		this.#announcer = new Announcer()
	}

	#announcer
	#apiCache

	/**
	 * Stores active layer
	 * @type {ActiveLayer}
	 */
	#activeLayer;

	/**
	 * Api key for meteocentrum
	 * @type {string}
	 */
	#apiKey = "HojkHhAZvncfKMcD1gyO";

	#player

	/**
	 * @type {MeteosourceApiProtect}
	 */
	#apiProtect;

	/**
	 * @private
	 * Store width and height for canvas from windyMapNode
	 * @type {CanvasComponent}
	 */
	#canvasComponent = new CanvasComponent();

	/**
	 * @private
	 * @type {PlaceBubbleController}
	 */
	#placeBubbleController;

	/**
	 * @private
	 * Stores dictionary
	 * @type {Dictionary}
	 */
	#dictionary;

	/**
	 * @private
	 * Stores forecast
	 * @type {ForecastLayer}
	 */
	#forecast;

	/**
	 * @private
	 * Stores map wrapper with loaded map
	 * @type {MapController}
	 */
	#mapWrapper;

	/**
	 * @private
	 * Is Windy paused because of map moving?
	 * @type {boolean}
	 */
	#isWindyPaused = false;

	/**
	 * Direct access to map from this.mapWrapper
	 */
	get #map() {
		return this.#mapWrapper?.map ?? null;
	}

	/**
	 * Stores place detail controller
	 * @type {PlaceDetail}
	 */
	#placeDetailController;

	/**
	 * Stores search controller
	 * @type {SearchController}
	 */
	#searchController;

	/**
	 * @private
	 * Stores slider.
	 * @type {Slider}
	 */
	#slider;

	/**
	 * Url search params
	 * @type {URLSearchParams}
	 */
	#urlParams = new URLSearchParams(window.location.search);

	/**
	 * Stores windy wrapper instance
	 * @type {WindyController}
	 */
	#windyWrapper;

	/**
	 * Edit search params in url
	 * @param {string} param2value
	 * @param {function} cb
	 */
	#editUrlParams = (param2value, cb) => {
		const searchParams = this.#urlParams;

		for(let param of Object.keys(param2value)) {
			let value = param2value[param]
			if (value === null && !searchParams.get(param)) {
				return;
			} else if (value === null) {
				searchParams.delete(param);
			} else {
				searchParams.set(param, value);
			}
		}

		const url = window.location

		const onlyUrl = url.href.replace(url.search,'');

		// push state
		window.history.pushState('object', "", `${onlyUrl}?${decodeURIComponent(searchParams.toString())}`);

		// callback
		if(typeof cb === "function") cb();
	}

	/**
	 * Get client position
	 */
	#getMyLocation  = () => {
		// prompt for user location
		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(location => {
				const {longitude, latitude} = location.coords;
				this.#searchLocationChosen(latitude.toString(), longitude.toString())
			})
		}
	}

	/**
	 * Handle map change viewport
	 */
	#mapMoveChanged = () => {
		this.#windyWrapper.refreshWindy();
	}

	/**
	 * Run application
	 * @returns {Promise<void>}
	 */
	start = async () => {

		const urlParams = this.#urlParams;
		let layer = urlParams.has('layer') ? urlParams.get("layer") : null
		const [latPlace, lonPlace] = parseLatLon(this.#urlParams.get("gps"))
		const [latCentre, lonCentre] = parseLatLon(this.#urlParams.get("centre"))
		const lang = this.#urlParams.has("lang") ? this.#urlParams.get("lang") : "en"
		const unit = this.#urlParams.has("unit") ? this.#urlParams.get("unit") : "metric"

		await this.#apiProtect.init()
		await this.#mapWrapper.init(latCentre, lonCentre)

		// we must call here so #map is defined
		this.#windyWrapper = new WindyController(this.#slider, this.#canvasComponent, this.#map);

		this.#dictionary.init(lang)
        this.#searchController.setLanguage(lang)
		this.#activeLayer.switchActiveLayer(layer)
		await this.#slider.init({lat: latPlace, lon: lonPlace, layer: layer, lang: lang})
        this.#placeDetailController.init({lat: latPlace, lon: lonPlace, reloadTable: true, layer: layer, date: this.#slider.value * 60 * 1000, lang: lang, unit: unit})
		this.#placeBubbleController.set({lat: latPlace, lon: lonPlace, variable: layer, unit: unit, lang: lang})
        this.#forecast.setUnit(unit)
		this.#forecast.setLang(lang)
		this.#forecast.renderLegend();
		this.#forecast.setShowLegend(!!layer)
		this.#apiCache.setUnit(unit)
		this.#updateWindyBlock(true)

		if(latPlace !== null && lonPlace !== null) {
			if(latCentre === null || lonCentre === null)
				this.#mapWrapper.placeZoom(latPlace, lonPlace);
			else
				this.#mapWrapper.placeZoom(latCentre, lonCentre);
			this.#slider.setForceHide(this.#activeLayer.type !== "rain")
			this.#placeDetailController.showDetailsTable()
		} else {
			this.#slider.setForceHide(false)
			this.#placeDetailController.hideDetailsTable()
		}

		let showRadarButtonStr = import.meta.env.VITE_SHOW_RADAR
		let showRadarButton = ["true", "1", "yes"].includes(showRadarButtonStr.toLowerCase())

		if(showRadarButton)
			$("#layerSelect button[name='rain']").first().show()

		this.#setListeners();
		this.#announcer.hide()
        setTimeout(this.#restartPage, 90*60*1000)
	}

	#isMovingMap = false

	#restartPage = () => {
		this.#announcer.setText(i18next.t("page must be reloaded"))
        setTimeout(() => {
        	location.reload()
		}, 5*1000)
	}

	#updateWindyBlock = (updateInput=false) => {
		if(this.#isMovingMap || this.#player.getState() !== "stopped" || this.#urlParams.get("wind-flow") === "false")
			this.#windyWrapper.toggleWindFlow(false)

		if(!this.#isMovingMap && this.#player.getState() === "stopped" && this.#urlParams.get("wind-flow") !== "false")
			this.#windyWrapper.toggleWindFlow(true)

		$(".windyPlay input").prop("checked", this.#urlParams.get("wind-flow") !== "false")
	}

	/**
	 * Set listeners
	 */
	#setListeners = () => {
		$(document).on("click", "#playButton", this.#playButtonPressed);
		$(document).on("click", "#playButtonSlider", this.#playButtonPressed)
		$(document).on("click", "#layerSelect .windyPlay label", e => {
		    if(this.#urlParams.get("wind-flow") !== "false")
		    	this.#editUrlParams({"wind-flow": "false"})
			else
				this.#editUrlParams({"wind-flow": "true"})
            this.#updateWindyBlock()
		});
		$(document).on("click", "#layerSelect button", this.#switchLayerHandle);
		$(document).on("change", "#extraLayersSelect", this.#switchLayerHandle);
		$(document).on("click", "#showHideLayerSelect button[name='showHide']", this.#toggleHamburger);
		$(document).on("click", "#search button[name='searchMyLocation']", this.#getMyLocation);
		$(document).on("keyup", "#search input[name='placeSearch']", this.#searchController.searchList);
		$(document).on("click", "#search button[name='searchButton']", this.#searchController.search);
		$(document).on("click", "#showHideLegend", () => this.#forecast.setShowLegend())
		this.#map.on("movestart", () => {
			this.#isMovingMap = true
			this.#updateWindyBlock()
		})
		this.#map.on("moveend", () => {
			this.#isMovingMap = false
			this.#updateWindyBlock()
			this.#editUrlParams({"centre": this.#mapWrapper.currentLat + "," + this.#mapWrapper.currentLon})
		})
		this.#map.on("click", async evt => {
			let [lon, lat] = toLonLat(evt.coordinate)
			this.#placeBubbleController.set({lat: lat, lon: lon})
			this.#editUrlParams({"place-detail": "true", "gps": lat + "," + lon});
			this.#slider.init({lat: lat, lon: lon})
			this.#placeDetailController.init({lat: lat, lon: lon, reloadTable: true, date: this.#slider.value * 60 * 1000})
			this.#placeDetailController.showDetailsTable()
			this.#slider.setForceHide(this.#activeLayer.type !== "rain")
		})
		$(document).on("click", "#bubbleClose", this.#placeDetailCloseClicked);
	}

	/**
	 * Callback for change slider value
	 */
	#sliderChanged = () => {
		this.#newDateSet()
	}

	#sliderUserChanged = () => {
		const state = this.#player.getState()
		if(state !== "stopped") {
			this.#player.stopImmediately()
			JQueryNodes.elements.playButton.removeClass("played")
			JQueryNodes.elements.playButtonSlider.removeClass("played")
		}
		this.#forecast.abortDownloads()
		this.#newDateSet()
	}

	#newDateSet = () => {
		this.#forecast.prepareForecast({date: this.#slider.value * 1000 * 60});
		this.#windyWrapper.refreshWindy();
		this.#placeBubbleController.set({date: this.#slider.value * 1000 * 60})
		this.#placeDetailController.init({date: this.#slider.value * 1000 * 60})
	}

	#placeDetailDateSelected = dateTime => {
		this.#forecast.abortDownloads()
		const state = this.#player.getState()
		if(state !== "stopped") {
			this.#player.stopImmediately()
			JQueryNodes.elements.playButton.removeClass("played")
			JQueryNodes.elements.playButtonSlider.removeClass("played")
		}
		this.#slider.init({value: dateTime})
		this.#newDateSet()
	}

	#placeDetailCloseClicked = () => {
		this.#placeDetailController.hideDetailsTable()
		this.#placeBubbleController.clear()
		this.#editUrlParams({"gps": null, "place-detail": null})
		this.#slider.setForceHide(this.#activeLayer.type === "rain")
		this.#slider.init({lat: null, lon: null})
	}

	#switchForecast = () => {
		this.#forecast.prepareForecast({date: this.#slider.value * 1000 * 60});
	}

	#searchLocationChosen = async (lat, lon) => {
		this.#slider.init({lat: lat, lon: lon})
		this.#mapWrapper.placeZoom(lat, lon);
		this.#placeBubbleController.set({lat: lat, lon: lon})
		this.#placeDetailController.init({lat: lat, lon: lon})
		this.#placeDetailController.showDetailsTable()
		this.#editUrlParams({"place-detail": "true", "gps": lat + "," + lon});
		this.#slider.setForceHide(this.#activeLayer.type !== "rain")
	}

	#loadingStateChanged = state => {
		const playerState = this.#player.getState()

		if((playerState === "playing" || playerState === "stopping")) {
			if(state === "preloading") {
				// nothing, simply keep playing
			} else if(state === "loading") {
				this.#player.pause();
				this.#slider.setLoadingCaption(true);
				this.#placeDetailController.showLoadingLabel(true);
			} else if(state === "loaded") {
				// keep playing
			} else {
				console.warn("wtf unknown state " + state)
			}
		}
		else if(playerState === "paused" || playerState === "paused_stopping") {
			if(state === "preloading") {
				// wait before everything is loaded
			} else if(state === "loading") {
				// wait before everything is loaded
			} else if(state === "loaded") {
				this.#player.continue();
				this.#slider.setLoadingCaption(false);
				this.#placeDetailController.showLoadingLabel(false);
			} else {
				console.warn("wtf unknown state " + state)
			}
		}
		else if(playerState === "stopped") {
			if(state === "preloading") {
				this.#slider.setLoadingCaption(false);
				this.#placeDetailController.showLoadingLabel(false);
			} else if(state === "loading") {
				this.#slider.setLoadingCaption(true);
				this.#placeDetailController.showLoadingLabel(true);
			} else if(state === "loaded") {
				this.#slider.setLoadingCaption(false);
				this.#placeDetailController.showLoadingLabel(false);
			} else {
				console.warn("wtf unknown state " + state)
			}
		}
		this.#updateWindyBlock()
	}

	#playerStateChanged = state => {
		this.#updateWindyBlock()
	}

	#playerFrame = date => {
		this.#slider.init({value: date})
		this.#newDateSet()
	}

	#playButtonPressed = () => {
		let state = this.#player.getState()
		if(state === "stopped") {
			this.#player.play({layer: this.#activeLayer.type,
				date: this.#slider.value * 60 * 1000,
				minDate: this.#slider.min * 60 * 1000,
				maxDate: this.#slider.max * 60 * 1000})
            JQueryNodes.elements.playButton.addClass("played")
			JQueryNodes.elements.playButtonSlider.addClass("played")

			if(this.#forecast.getState() === "loading")
				this.#player.pause()
		}
		else if(state === "playing") {
			this.#player.stop()
			JQueryNodes.elements.playButton.removeClass("played")
			JQueryNodes.elements.playButtonSlider.removeClass("played")
		}
		else if(state === "paused") {
			this.#player.stop()
			JQueryNodes.elements.playButton.removeClass("played")
			JQueryNodes.elements.playButtonSlider.removeClass("played")
		}
	}

	/**
	 * Switch layers handle
	 * @param {jQuery.Event} e
	 */
	#switchLayerHandle = async (e) => {
		// get type of layer
        const prevType = this.#activeLayer.type
		const type = e.type === "change" ? e.currentTarget.value : e.currentTarget.name;

        const switchingToRain = (prevType !== "rain" && type === "rain")
		const switchingFromRain = (prevType === "rain" && type !== "rain")
        if((switchingToRain || switchingFromRain) && this.#player.getState() !== "stopped") {
			this.#player.stopImmediately()
			JQueryNodes.elements.playButton.removeClass("played")
			JQueryNodes.elements.playButtonSlider.removeClass("played")
		}

		this.#forecast.abortDownloads()
        this.#forecast.clearTiles()
		this.#activeLayer.switchActiveLayer(type);
		this.#forecast.renderLegend();
		await this.#slider.init({value: this.#slider.value * 1000 * 60, layer: type});
		this.#placeDetailController.init({layer: type, date: this.#slider.value * 1000 * 60})
		this.#placeBubbleController.set({variable: type})
		this.#editUrlParams({"layer": type});

		const switchingFromEmpty = (prevType === null && type !== null)
		if(switchingFromEmpty)
			this.#forecast.setShowLegend(true)

		if(this.#placeBubbleController.isBubble()) {
			this.#slider.setForceHide(this.#activeLayer.type !== "rain")
			if(type === "rain")
				this.#placeDetailController.hideDetailsTable()
			else
				this.#placeDetailController.showDetailsTable()
		} else {
			this.#placeDetailController.hideDetailsTable()
			this.#slider.setForceHide(false)
		}
	}

	/**
	 * Toggle hamburger menu
	 */
	#toggleHamburger = () => {
		const {layerSelect, showHideLayerSelect} =  JQueryNodes.elements;

		if(!layerSelect.hasClass("opened")) {
			layerSelect.addClass("opened");
			showHideLayerSelect.addClass("opened");
		} else {
			layerSelect.removeClass("opened");
			showHideLayerSelect.removeClass("opened");
		}
	}
}
