import LayerConfig from "./LayerConfig";

export default class ActiveLayer {

	/**
	 * List of properties
	 */
	static PARAM_PLAYER_TIME = "playerTime";
	static PARAM_SLIDER_STEP = "sliderStep";
	static PARAM_LAYER_TYPE = "layerType";
	static PARAM_VECTOR_VARIABLE = "vectorVariable";
	static PARAM_COLORS = "colors";
	static PARAM_LAST_SLIDER_HOURS = "lastSliderHours";
	static PARAM_LEGEND_TITLES = "legendTitles";
	static PARAM_PLACE_DETAIL_SHOW = "placeDetailShow";
	static PARAM_FIRST_STEP_ROUND = "firstStepRound";

	/**
	 * Constructor
	 * @param {string} type
	 * @param {function} switchActiveLayerCb
	 */
	constructor(type,switchActiveLayerCb) {
		this.#type = type;
		this.#switchActiveLayerCb = switchActiveLayerCb;
		this.#setConfig();
	}

	/**
	 * Stores layer config
	 */
	#config = LayerConfig.getLayerConfig();

	/**
	 * Callback after switch layer
	 * @type {function}
	 */
	#switchActiveLayerCb;

	/**
	 * Type of layer
	 * @type {string}
	 */
	#type;
	get type() {
		return this.#type;
	}

	/**
	 * Returns configuration property
	 * @param {string} propertyName
	 * @returns {*}
	 */
	getConfigValue = (propertyName) => {
		return this.#config[propertyName];
	}

	/**
	 * Get color configuration for feature
	 */
	getColors = (feature) => {
        if (feature.get('min_val') == feature.get('max_val') && feature.get('max_val') == 0 && this.#type === LayerConfig.TYPE_RAIN) {
        	// tmp turned off because it doesn't work well
            return "rgba(120, 120, 120, 0)";
        }
		const actualValue = feature.get('min_val');
		const colors = this.getConfigValue(ActiveLayer.PARAM_COLORS);
		let color;
		let previousItem;

		for(let i in colors) {
			const item = colors[i];
			if(i == 0) previousItem = item;
			if(item.value < actualValue) {
				const interpolatedColor = [];
				if(!previousItem) debugger;
				const deltaCoeff = (previousItem.value - actualValue) / (previousItem.value - item.value);

				interpolatedColor[0] = item.color[0] * deltaCoeff + (1-deltaCoeff) * previousItem.color[0];
				interpolatedColor[1] = item.color[1] * deltaCoeff + (1-deltaCoeff) * previousItem.color[1];
				interpolatedColor[2] = item.color[2] * deltaCoeff + (1-deltaCoeff) * previousItem.color[2];
				if(this.#type === LayerConfig.TYPE_TEMPERATURE_TRIAL) {
					color = "rgba("+interpolatedColor[0]+","+interpolatedColor[1]+","+interpolatedColor[2]+", .61)";
				} else {
					color = "rgba("+interpolatedColor[0]+","+interpolatedColor[1]+","+interpolatedColor[2]+", .7)";
				}
				break;
			}
			if(Number(i) + 1 === colors.length) {
				if(this.#type === LayerConfig.TYPE_TEMPERATURE_TRIAL) {
					color = "rgba("+item[0]+","+item[1]+","+item[2]+", .61)";
				} else {
					color = "rgba("+item.color[0]+","+item.color[1]+","+item.color[2]+", 0)";
				}
				break;
			}
			previousItem = item;
		}
		return color;
	}

	/**
	 * Returns title of unit for active layer
	 * @param {string} unit
	 * @returns {string}
	 */
	getLegendTitle = (unit) => {
		const legendTitles = this.getConfigValue(ActiveLayer.PARAM_LEGEND_TITLES);
		return legendTitles[unit];
	}

	/**
	 * Set layer config
	 */
	#setConfig = () => {
		 this.#config = LayerConfig.getLayerConfig(this.#type);
	}

	/**
	 * Switch between active layers
	 * @param {string} type
	 */
	switchActiveLayer = (type) => {
		this.#type = type;
		this.#setConfig();
		this.#switchActiveLayerCb();
	}
}