import JQueryNodes from "./JQueryNodes";

export default class SearchController {

	/**
	 * Constructor
	 * @param {MapController} mapWrapper
	 * @param {MeteosourceApiProtect} apiProtect
	 * @param {URLSearchParams} urlParams
	 * @param {function} isLanguageAvailableCb
	 * @param {function} locationChosenCb
	 */
	constructor(mapWrapper, apiProtect, isLanguageAvailableCb, locationChosenCb) {
		this.#mapWrapper = mapWrapper;
		this.#apiProtect = apiProtect;
		this.#isLanguageAvailableCb = isLanguageAvailableCb;
		this.#locationChosenCb = locationChosenCb;
		this.#endpointUrl = import.meta.env.VITE_API_PREFIX + "find_places"
		this.#endpointUrlPrefix = import.meta.env.VITE_API_PREFIX + "find_places_prefix"

		$(document).on("click", "#search .list .searchList .place", this.getSearchListHandle);
	}

	/**
	 * @type {MeteosourceApiProtect}
	 */
	#apiProtect;

	/**
	 * @type {string}
	 */
	#endpointUrl;

	/**
	 * @type {string}
	 */
	#endpointUrlPrefix;

	/**
	 * @private
	 * Callback for location selection
	 * @type {function}
	 */
	#locationChosenCb

	/**
	 * Callback for check language
	 * @type {function}
	 */
	#isLanguageAvailableCb

	/**
	 * Stores map object
	 * @type {MapController}
	 */
	#mapWrapper;

	#lang = "en"

	/**
	 * Handle click on place in list of places
	 * @param {Event} e - click event
	 */
	getSearchListHandle = (e) => {
		var lon = $(e.currentTarget).attr("data-lon");
		var lat = $(e.currentTarget).attr("data-lat");

		lat = lat.endsWith("N") ? lat.substr(0, lat.length-1) : ("-"+lat.substr(0, lat.length-1))
		lon = lon.endsWith("E") ? lon.substr(0, lon.length-1) : ("-"+lon.substr(0, lon.length-1))

		this.#locationChosenCb(lat, lon)
	}

	setLanguage = lang => {
		this.#lang = lang
	}

	/**
	 * Run search
	 */
	search = () => {
		// get search node
		const {search} = JQueryNodes.elements;

		// get input value
		const value = search.find("input").val();

		// there is no value
		if(value.length === 0) return;

		// build url
		const dataUrl = `${this.#endpointUrl}?key=${this.#apiProtect.getApiKey()}&text=${value}`;
		fetch(dataUrl)
			.then((res) => res.json())
			.then((data) => {
				if(data.length === 0) return;

				this.#mapWrapper.placeZoom(data[0].lat, data[0].lon);
			});
	}

	/**
	 * Search list of palces
	 * @param {Event} e - keyup event
	 */
	searchList = (e) => {
		// get search node
		const {search} = JQueryNodes.elements;

		// get input value
		const value = search.find("input").val();

		// check press enter
		if(e.originalEvent.keyCode === 13) {
			this.search();
		}

		// check length
		if(value.length < 2) {
			//delete old search place
			search.find(".list .searchList").empty();
			return;
		} else {
			this.searchWindyData(value);
		}
	}

	/**
	 * Search windy data locations
	 * @param {string} value
	 */
	searchWindyData = async (value) => {
		// get search node
		const {search} = JQueryNodes.elements;

		// get language
		const lng = this.#lang

		// build url
		const dataUrl = `${this.#endpointUrlPrefix}?key=${this.#apiProtect.getApiKey()}&text=${value}&language=${lng}`;

		// fetch data url
		const response = await fetch(dataUrl);
		const json = await response.json();

		//delete old search place
		search.find(".list .searchList").empty();

		const places = {};

		for(let i = 0; i < json.length; i++) {
			if(json[i].name in places) {
				places[json[i].name] = places[json[i].name] + 1;
			} else {
				places[json[i].name] = 1;
			}
		}

		for(const ant in json) {
			const data = json[ant];
			const placeName  = json[ant].name;

			if(places[placeName] > 1 && data.adm_area2) {
				search.find(".list .searchList").append($(`<button class="place" data-placeId="${data.place_id}" data-lon="${data.lon}" data-lat="${data.lat}">${data.name}<br><small>${data.adm_area2} - ${data.country}</small></button>`))
			} else {
				search.find(".list .searchList").append($(`<button class="place" data-placeId="${data.place_id}" data-lon="${data.lon}" data-lat="${data.lat}">${data.name}<br><small>${data.country}</small></button>`))
			}
		}
	}
}