import "/src/jquery";
import "/src/jquery-ui"
import "/src/jquery-ui.css";
import "/src/functions.js";
import "./home.scss";

import App from "/src/classes/App";

const app = new App();
Promise.resolve(app.start()).catch(e => {
    console.log("ERROR while loading")
    console.log(e)
});







