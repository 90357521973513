export default class Player {

    constructor(playerStateChangedCb, playerFrameCb) {
        this.#playerStateChangedCb = playerStateChangedCb
        this.#playerFrameCb = playerFrameCb
    }

    #playerStateChangedCb
    #playerFrameCb
    #state = "stopped"
    #layer = null
    #date = null
    #inc = null
    #stopInc = null
    #maxDate = null
    #minDate = null
    #prevTimestamp = null
    #secondsPerHour = 0.04
    #frame = null

    getState = () => this.#state

    #intervalCb = timestamp => {

        let is10minutes = this.#date % (10*60*1000) === 0
        let interval
        if(is10minutes && this.#layer === "rain") {
            interval = this.#secondsPerHour * 1000 * 15 * 2
        } else if(this.#layer === "rain") {
            interval = this.#secondsPerHour * 100 * 10
        } else {
            interval = this.#secondsPerHour * 1000
        }

        if(this.#prevTimestamp === null || timestamp - this.#prevTimestamp > interval) {
            this.#prevTimestamp = timestamp

            // prepare new value
            if(this.#state === "stopped" || this.#state === "paused" || this.#state === "paused_stopping") {
                this.#frame = null
                return
            }
            else if(this.#state === "stopping" && this.#date % this.#stopInc === 0) {
                this.#state = "stopped"
                this.#playerStateChangedCb(this.#state)
                this.#frame = null
                return
            }
            else if(this.#state === "playing" && this.#date + this.#inc > this.#maxDate) {
                this.#date = this.#minDate
            }
            else {
                this.#date = this.#date + this.#inc
            }
            this.#playerFrameCb(this.#date)

            this.#frame = requestAnimationFrame(this.#intervalCb)
        } else {
            this.#frame = requestAnimationFrame(this.#intervalCb)
        }
    }

    play(args) {
        if(args.layer === undefined || !args.date || !args.minDate || !args.maxDate)
            throw "Player.play: no date or layer or minDate or maxDate arg"
        if(this.#state !== "stopped")
            throw "Player.play: cannot play, the current state is " + this.#state

        this.#state = "playing"
        this.#layer = args.layer
        this.#inc = (this.#layer === "rain") ? (60*1000) : (5*60*1000)
        this.#stopInc = (this.#layer === "rain") ? (10*60*1000) : (60*60*1000)
        this.#date = args.date
        this.#minDate = args.minDate
        this.#maxDate = args.maxDate
        this.#prevTimestamp = null

        this.#frame = requestAnimationFrame(this.#intervalCb)
        this.#playerStateChangedCb(this.#state)
    }

    stop() {
        if(this.#state === "playing")
            this.#state = "stopping"
        else if(this.#state === "paused")
            this.#state = "paused_stopping"
        else
            throw "Player.stop: cannot stop, the current state is " + this.#state
        this.#playerStateChangedCb(this.#state)
    }

    pause() {
        if(this.#state === "stopping")
            this.#state = "paused_stopping"
        else if(this.#state === "playing")
            this.#state = "paused"
        else
            throw "Player.pause: cannot stop, the current state is " + this.#state
        this.#playerStateChangedCb(this.#state)
    }

    continue() {
        if(this.#state === "paused")
            this.#state = "playing"
        else if(this.#state === "paused_stopping")
            this.#state = "stopping"
        else
            throw "Player.continue: cannot stop, the current state is " + this.#state
        this.#playerStateChangedCb(this.#state)
        this.#frame = requestAnimationFrame(this.#intervalCb)
    }

    stopImmediately() {
        this.#state = "stopped"
        this.#playerStateChangedCb(this.#state)
        if(this.#intervalCb !== null)
            cancelAnimationFrame(this.#intervalCb)
    }


}
