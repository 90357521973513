export default class LayerConfig {
	/**
	 * List of layer types
	 */
	static TYPE_DEFAULT                 = "defaultLayer";
	static TYPE_TEMPERATURE             = "temperature";
	static TYPE_FEELS_LIKE_TEMPERATURE  = "feels_like_temperature";
	static TYPE_CLOUDS                  = "clouds";
	static TYPE_PRECIPITATION           = "precipitation";
	static TYPE_WIND_SPEED              = "wind_speed";
	static TYPE_WIND_GUST               = "wind_gust";
	static TYPE_PRESSURE                = "pressure";
	static TYPE_HUMIDITY                = "humidity";
    static TYPE_AIR_QUALITY             = "air_quality";
    static TYPE_SIGNIFICANT_WEATHER     = "significant_weather";
	static TYPE_RAIN                    = "rain";
	static TYPE_RAIN_HIGHRES            = "rain_highres";
	static TYPE_TEMPERATURE_TRIAL       = "temperatureTrial";
	static TYPE_PRECIPITATION_TRIAL     = "precipitationTrial";

	static LAYER_TYPE_VECTOR    = "vector";
	static LAYER_TYPE_RASTER    = "raster";


	/**
	 * Layers configuration
	 */
	static defaultLayer = {
		playerTime: 154*4*3,
		sliderStep: 5,
		lastSliderHours: 0,
		layerType: LayerConfig.LAYER_TYPE_RASTER,
		vectorVariable: null,
		colors: null,
		firstStepRound: "hour",
		legendTitles: {
			temperature: "°C",
			feels_like_temperature: "°C",
			clouds: "%",
			precipitation: "mm/h",
			wind_speed: "m/s",
			wind_gust: "m/s",
			pressure: "hPa",
			humidity: "%",
            air_quality: "Index",
			rain: "mm/h",
			rain_highres: "mm/h",
			temperatureTrial: "°C",
			precipitationTrial: "mm/h",
            significant_weather: "Index",
		},
		placeDetailShow: true,
	};

	static temperature = {
		legendTitles: {
			ca: "°C",
			metric: "°C",
			uk: "°C",
			us: "°F",
		}
	};

	static feels_like_temperature = {
		legendTitles: {
			ca: "°C",
			metric: "°C",
			uk: "°C",
			us: "°F",
		}
	};

	static clouds = {
		legendTitles: {
			ca: "%",
			metric: "%",
			uk: "%",
			us: "%",
		}
	};

	static precipitation = {
		legendTitles: {
			ca: "mm/h",
			metric: "mm/h",
			uk: "mm/h",
			us: "in/h",
		}
	};

	static wind_speed = {
		legendTitles: {
			ca: "km/h",
			metric: "m/s",
			uk: "mph",
			us: "mph",
		}
	};

	static wind_gust = {
		legendTitles: {
			ca: "km/h",
			metric: "m/s",
			uk: "mph",
			us: "mph",
		}
	};

	static pressure = {
		legendTitles: {
			ca: "kPa",
			metric: "hPa",
			uk: "hPa",
			us: "Hg",
		}
	};

	static humidity = {
        legendTitles: {
            ca: "%",
            metric: "%",
            uk: "%",
            us: "%",
        }
    };

    static air_quality = {
		playerTime: 94*4*3,
        legendTitles: {
            ca: "Index",
            metric: "Index",
            uk: "Index",
            us: "Index",
        }
    };
    
    static significant_weather = {
		playerTime: 94*4*3,
        layerType: LayerConfig.LAYER_TYPE_VECTOR,
        vectorVariable: "significant_weather",
        legendTitles: {
            ca: "Index",
            metric: "Index",
            uk: "Index",
            us: "Index",
        },
        colors: [
            {
                value: 9,
                color: [0, 100, 0],
            },
            {
                value: 8,
                color: [60, 179, 113],
            },
            {
                value: 7,
                color: [0, 250, 154],
            },
            {
                value: 6,
                color: [0, 0, 139],
            },
            {
                value: 5,
                color: [0, 0, 255],
            },
            {
                value: 4,
                color: [0, 191, 255],
            },
            {
                value: 3,
                color: [255, 140, 0],
            },
            {
                value: 2,
                color: [255, 255, 0],
            },
            {
                value: 1,
                color: [173, 0, 0],
            },
            {
                value: 0,
                color: [255, 255, 255],
            },
        ],
    };

	static rain_highres = {
		playerTime: 33,
		sliderStep: 10,
		lastSliderHours: 4,
		legendTitles: {
			ca: "mm/h",
			metric: "mm/h",
			uk: "mm/h",
			us: "in/h",
		}
	};

	static rain = {
		playerTime: 12*3*5,
		sliderStep: 1,
		lastSliderHours: 2,
		firstStepRound: "10minutes",
		layerType: LayerConfig.LAYER_TYPE_VECTOR,
		vectorVariable: "rain",
        legendTitles: {
            ca: "mm/h",
            metric: "mm/h",
            uk: "mm/h",
            us: "in/h",
        },
		colors: [
            {
                value: 160,
                color: [225, 25, 0],
            },
            {
                value: 100,
                color: [255, 125, 0],
            },
            {
                value: 63,
                color: [255, 160, 0],
            },
            {
                value: 40,
                color: [255, 200, 0],
            },
            {
                value: 25,
                color: [255, 255, 0],
            },
            {
                value: 16,
                color: [200, 255, 0],
            },
            {
                value: 10,
                color: [150, 255, 0],
            },
            {
                value: 6.3,
                color: [100, 255, 0],
            },
            {
                value: 4,
                color: [0, 200, 50],
            },
            {
                value: 2.5,
                color: [0, 150, 150],
            },
            {
                value: 1.6,
                color: [0, 100, 255],
            },
            {
                value: 1,
                color: [50, 50, 200],
            },
            {
                value: 0.63,
                color: [220, 0, 220],
            },
            {
                value: 0.4,
                color: [175, 0, 175],
            },
            {
                value: 0.25,
                color: [100, 0, 100],
            },
            {
                value: 0,
                color: [0, 0, 0],
            },		],
		placeDetailShow: false,
	};

	static temperatureTrial = {
		layerType: LayerConfig.LAYER_TYPE_VECTOR,
		vectorVariable: "temperature",
        legendTitles: {
            ca: "°C",
            metric: "°C",
            uk: "°C",
            us: "°F",
        },
		colors: [
			{
				value: 315.00,
				color: [255, 255, 255],
			},
			{
				value: 313.15,
				color: [255, 255, 255],
			},
			{
				value: 308.48,
				color: [238, 238, 174],
			},
			{
				value: 303.82,
				color: [232, 152, 203],
			},
			{
				value: 299.15,
				color: [184, 9, 19],
			},
			{
				value: 294.48,
				color: [252, 169, 15],
			},
			{
				value: 289.82,
				color: [237, 237, 7],
			},
			{
				value: 285.15,
				color: [227, 255, 212],
			},
			{
				value: 280.48,
				color: [17, 217, 74],
			},
			{
				value: 275.82,
				color: [14, 230, 230],
			},
			{
				value: 271.15,
				color: [21, 25, 148],
			},
			{
				value: 266.48,
				color: [170, 219, 252],
			},
			{
				value: 261.82,
				color: [158, 16, 106],
			},
			{
				value: 257.15,
				color: [169, 167, 201],
			},
			{
				value: 252.48,
				color: [255, 255, 255],
			},
			{
				value: 247.82,
				color: [110, 28, 184],
			},
			{
				value: 243.15,
				color: [0, 0, 0],
			},
			{
				value: 240,
				color: [0, 0, 0],
			},
		],
	};

	static precipitationTrial = {
		layerType: LayerConfig.LAYER_TYPE_VECTOR,
		vectorVariable: "precipitation",
        legendTitles: {
            ca: "mm/h",
            metric: "mm/h",
            uk: "mm/h",
            us: "in/h",
        },
		colors: [
			{
				value: 60,
				color: [225, 25, 0 ],
			},
			{
				value: 50,
				color: [84, 16, 41 ],
			},
			{
				value: 40,
				color: [147,23,78],
			},
			{
				value: 30,
				color: [179,56,103],
			},
			{
				value: 20,
				color: [209,95,94],
			},
			{
				value: 15,
				color: [218,122,78],
			},
			{
				value: 10,
				color: [217,156,67],
			},
			{
				value: 8,
				color: [219,181,63],
			},
			{
				value: 6,
				color: [207,219,63],
			},
			{
				value: 4,
				color: [143,204,74],
			},
			{
				value: 2,
				color: [82,183,100],
			},
			{
				value: 1,
				color: [70,153,171],
			},
			{
				value: 0.5,
				color: [75,104,172],
			},
			{
				value: 0.2,
				color: [94,91,141],
			},
			{
				value: 0,
				color: [0, 0, 0 ],
			},
		],
	};

	/**
	 * Return Unit for unit and layer/type
	 * @param {string} type
	 * @param {string} unit
	 */
	static getUnitForType = (type, unit = "metric") => {
		return LayerConfig[type].legendTitles[unit];
	}

	/**
	 * Returns layer config for layer
	 * @param layer
	 */
	static getLayerConfig = (layer) => {
		if(!layer) return LayerConfig.defaultLayer;

		// get config for layer
		const config = LayerConfig[layer] ?? {};

		// merge configs
		return {...LayerConfig.defaultLayer, ...config}
	}


}