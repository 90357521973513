import Windy from "../windy";
import {transformExtent} from "ol/proj";
import JQueryNodes from "./JQueryNodes";

export default class WindyController {

	/**
	 * Constructor
	 * @param slider
	 * @param map
	 */
	constructor(slider, canvasWrapper, map) {
		this.#slider = slider;
		this.#canvasWrapper = canvasWrapper;
		this.#map = map;
		this.#url = import.meta.env.VITE_WINDY_URL;

		// call init
		this.refreshWindy();
	}

	/**
	 * URL of the Windy JSON files (source files)
	 * @type {string}
	 */
	#url;

	/**
	 * Stores canvas wrapper
	 * @type {CanvasComponent}
	 */
	#canvasWrapper;

	/**
	 * Show/hide wind flow
	 * @type {boolean}
	 */
	#isFlowPlaying = true;

	/**
	 * Stores maps
	 */
	#map;

	/**
	 * Stores slider instance
	 * @type {Slider}
	 */
	#slider;

	/**
	 * Stores Windy data
	 * @type {Windy}
	 */
	#windy;

	/**
	 * Is toggling Windy on/off in progress?
	 * @type {boolean}
	 */
	#isTogglingInProgress = false;

	/**
	 * Return url
	 * @param {number} value
	 * @returns {string}
	 */
	#getWindyDataUrl = (value) => {
		const date = this.#slider.getDateFromValue(value);
		const dateTimeString = date.setZone("UTC").toFormat("yyyy-LL-dd_HH")

		return this.#url + dateTimeString+"_small.json";
	}

	/**
	 * Init windy
	 */
	#init = () => {

	}

	#currentValue = null

	/**
	 * Refresh windy data
	 */
	refreshWindy = async () => {
		try {
			if (this.#isFlowPlaying === false) return;
			this.#currentValue = this.#slider.value

			// get windy data url
			const windyDataUrl = this.#getWindyDataUrl(this.#slider.value);

			// fetch windy data url
			let response = await fetch(windyDataUrl)
			let jsonData = await response.json()
			this.#processWindyData(jsonData)
		}
		catch (e) {
			console.error("cannot refresh Windy:", e)
		}
	}

	/**
	 * Process response from windy
	 * @param data
	 */
	#processWindyData = (data) => {
		if(this.#windy) {
			this.#windy.stop();
		} else {
			this.#windy = new Windy({
				canvas: this.#canvasWrapper.node[0],
				data: data,
				zoom : this.#map.getView().getZoom()
			});
		}
		this.#windy.params.data = data;

		if(!this.#windy) return;
		if(this.#isFlowPlaying === false) return;

		this.#windy.stop();

		const canvas = this.#canvasWrapper.node[0];
		const mapSize = this.#map.getSize();
		let extent = this.#map.getView().calculateExtent(mapSize);

		extent = transformExtent(extent, "EPSG:3857", "EPSG:4326");

		canvas.width = mapSize[0];
		canvas.height = mapSize[1];

		this.#windy.start(
			[[0,0], [canvas.width, canvas.height]],
			canvas.width,
			canvas.height,
			[[extent[0], extent[1]],[extent[2], extent[3]]],
			this.#map.getView().getZoom()
		);
	}

	isFlowPlaying = () => this.#isFlowPlaying;

	/**
	 * Start/stop wind flowing
	 * @param playing
	 */
	toggleWindFlow = (playing = null) => {
	    if(playing === this.#isFlowPlaying)
	    	return
		if(this.#isTogglingInProgress)
			return;
		this.#isTogglingInProgress = true;

		try {
			const layerSelectNode = JQueryNodes.elements.layerSelect;

			if (playing !== null) {
				this.#isFlowPlaying = playing;
			} else {
				this.#isFlowPlaying = !this.#isFlowPlaying;
			}

			// refresh or sto windy
			let prom;
			if (this.#isFlowPlaying) {
				prom = this.refreshWindy();
			} else {
				this.#windy?.stop();
				prom = Promise.resolve()
			}

			prom.then(() => {

				// edit styles
				this.#canvasWrapper.node.css("display", this.#isFlowPlaying ? "block" : "none");

			}).finally(() => {
				this.#isTogglingInProgress = false;
			})
		}
		finally {
			this.#isTogglingInProgress = false;
		}
	}
}