import LayerConfig from "./LayerConfig";
import {DateTime} from "luxon";
import JQueryNodes from "./JQueryNodes";
import i18next from "i18next";
import {getAvailableTimes} from "./Slider";
import { formatDate } from "./Utils";

export default class PlaceDetail {

    /**
     * Constructor
     * @param {MapController} mapWrapper
     * @param {ActiveLayer} activeLayer
     * @param {function} isLanguageAvailableCb
     * @param {MeteosourceApiProtect} apiProtect
     * @param {ApiCache} apiCache
     */
    constructor(mapWrapper, activeLayer, isLanguageAvailableCb,
                apiProtect, dateChosenCb, closeClickedCb, apiCache) {
        this.#mapWrapper = mapWrapper;
        this.#activeLayer = activeLayer;
        this.#isLanguageAvailableCb = isLanguageAvailableCb;
        this.#endpointUrl = import.meta.env.VITE_API_PREFIX + "point"
        this.#apiProtect = apiProtect
        this.#dateChosenCb = dateChosenCb
        this.#closeClickedCb = closeClickedCb
        this.#isTouchscreen = ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 )
        this.#apiCache = apiCache

        $(document).on("click", "#placeDetailLabels .close", closeClickedCb);
        this.#setTouch()
    }

    #setTouch = () => {
        let selPlaceForecast = $("#placeForecast")
        let selTimeController = $("#time-controller")
        let lastDateMovingChosen = null
        let lastX = null
        let moving = false
        let state = "none"
        let scrollTimeout = null
        selTimeController.on("mousedown", e => {
            moving = true
            lastX = e.clientX
            e.preventDefault()
        })
        if(this.#isTouchscreen) {
            selPlaceForecast.on("touchstart", e => {
                if(state === "none") {
                    state = "touching"
                    lastDateMovingChosen = this.#chosenDateTime
                } else if(state === "scrolling") {
                    state = "touching_scrolling"
                } else {
                    throw "strange state " + state
                }
            })
        }

        $(document).on("mousemove", e => {
            if(!moving) return
            let diffX = e.clientX - lastX
            selTimeController.scrollLeft(selTimeController.scrollLeft() - diffX)
            lastX = e.clientX
            e.preventDefault()
        })
        if(this.#isTouchscreen) {
            selTimeController.on("scroll", e => {
                if(state === "none") {
                    // not handled by us
                }
                else if(state === "touching" || state === "touching_scrolling") {
                    state = "touching_scrolling"
                    let date = this.#getCentralDate()
                    if (lastDateMovingChosen)
                        $(".date-" + lastDateMovingChosen).removeClass("chosen")
                    lastDateMovingChosen = date
                    $(".date-" + lastDateMovingChosen).addClass("chosen")
                }
                else if(state === "scrolling") {
                    if(scrollTimeout)
                        clearTimeout(scrollTimeout)
                    scrollTimeout = setTimeout(() => {
                        if(state === "scrolling") {
                            state = "none"
                            this.#chosen(DateTime.fromMillis(lastDateMovingChosen))
                        }
                        scrollTimeout = null
                    }, 200)

                    let date = this.#getCentralDate()
                    if (lastDateMovingChosen)
                        $(".date-" + lastDateMovingChosen).removeClass("chosen")
                    lastDateMovingChosen = date
                    $(".date-" + lastDateMovingChosen).addClass("chosen")
                }
                else {
                    throw "strange state " + state
                }
            })
        }

        $(document).on("mouseup", e => {
            if(!moving) return
            moving = false
            e.preventDefault()
        })
        if(this.#isTouchscreen) {
            $(document).on("touchend", e => {
                if(state === "none") {
                    // do nothing
                }
                else if(state === "touching") {
                    state = "none"
                    this.#chosen(DateTime.fromMillis(lastDateMovingChosen))
                }
                else if(state === "touching_scrolling") {
                    state = "scrolling"
                    if(scrollTimeout)
                        clearTimeout(scrollTimeout)
                    scrollTimeout = setTimeout(() => {
                        if(state === "scrolling") {
                            state = "none"
                            this.#chosen(DateTime.fromMillis(lastDateMovingChosen))
                        }
                        scrollTimeout = null
                    }, 200)
                }
                else {
                    throw "strange state " + state
                }
            })
        }
        $(window).on("resize", e => {
            if(this.#table && this.#isTouchscreen) {
                this.#table.css("margin-left", (window.innerWidth/2) + "px")
                this.#table.css("margin-right", (window.innerWidth/2) + "px")
                if(this.#chosenDateTime)
                    this.#scrollTo(this.#chosenDateTime)
            }
        })
    }

    #dateChosenCb
    #closeClickedCb
    #tdHourElements
    #table = null
    #isTouchscreen
    #apiCache

    /**
     * Stores layer config
     * @type {ActiveLayer}
     */
    #activeLayer;


    /**
     * Addr of endpoint /point
     */
    #endpointUrl;

    /**
     * @type {MeteosourceApiProtect}
     */
    #apiProtect;

    /**
     * Callback for check language
     * @type {function}
     */
    #isLanguageAvailableCb

    /**
     * Stores map object
     * @type {MapController}
     */
    #mapWrapper;

	#lat = null
    #lon = null
    #layer = null
    #detailsShown = false
    #lang = "en"
    #unit = "metric"

    #scrollTo = chosenDateTime => {
	    let el = $(".date-" + chosenDateTime)
        let scrollLeft = el.offset().left - $(window).width()/2 - el.width()/2
        scrollLeft = el.position().left + 2*96 - $(window).width()/2 + el.width()/2
        scrollLeft = Math.max(0, scrollLeft)
        $("#time-controller").animate({
            scrollLeft: scrollLeft,
        }, 300)
    }

    #tableDateTimes = []
    #initPromise = null

    /**
     * Init place detail
     */
    init = async (args) => {
        if(this.#initPromise) {
            let otherPromise = this.#initPromise
            this.#initPromise = this.#mainInit(args, otherPromise)
        } else {
            this.#initPromise = this.#mainInit(args)
        }
        await this.#initPromise
    }

    #mainInit = async (args, waitPromise) => {
        if(waitPromise) {
            try {
                await waitPromise
            } catch(e) {}
        }

        let reloadTable = args.reloadTable || ("lat" in args && this.#lat !== args.lat) || ("lon" in args && this.#lon !== args.lon) || ("layer" in args && this.#layer !== args.layer)

        if ("lat" in args)
            this.#lat = args.lat
        if ("lon" in args)
            this.#lon = args.lon
        if ("layer" in args)
            this.#layer = args.layer
        if ("lang" in args)
            this.#lang = args.lang
        if ("unit" in args)
            this.#unit = args.unit


        if (reloadTable) {
            let oldChosenDateTime = this.#chosenDateTime
            this.#chosenDateTime = null
            await this.#reloadTable()
            if(this.#detailsShown && this.#layer !== "rain")
                this.showDetailsTable()
            else
                this.hideDetailsTable()
            this.#setChosenDateTime(args.date ? args.date : oldChosenDateTime)
        }
        else if("date" in args) {
            this.#setChosenDateTime(args.date)
        }

        if("lang" in args) {
            $("#placeDetailDataDate").text(i18next.t("date").toLowerCase())
            $("#placeDetailDataHour").text(i18next.t("hour").toLowerCase())
            $("#placeDetailDataTemperature").text(i18next.t("temperature").toLowerCase())
            $("#loadingLabel").text(i18next.t("loading..."))
            $("#placeDetailDataWind").text(i18next.t("wind") + " (" + LayerConfig.getUnitForType(LayerConfig.TYPE_WIND_SPEED, this.#unit) + ")")
            $("#placeDetailDataWeather").text(i18next.t("weather"))
        }
    }

    #chosenDateTime = null

    #setChosenDateTime = (newChosenDateTime) => {
        if(this.#tableDateTimes.length === 0)
            return

        let idx
        if(this.#layer !== "rain")
            idx = Math.round((newChosenDateTime - this.#tableDateTimes[0]) / (3*60*60*1000))
        else
            idx = Math.round((newChosenDateTime - this.#tableDateTimes[0]) / (10*60*1000))
        idx = Math.max(0, Math.min(this.#tableDateTimes.length - 1, idx))
        newChosenDateTime = this.#tableDateTimes[idx]
        if(this.#chosenDateTime === newChosenDateTime)
            return

        if(this.#chosenDateTime !== null)
            $(".date-" + this.#chosenDateTime).removeClass("chosen")
        this.#chosenDateTime = newChosenDateTime

        if(newChosenDateTime !== null) {
            $(".date-" + newChosenDateTime).addClass("chosen")
            $(".date-" + newChosenDateTime).removeClass("focused")
            this.#scrollTo(newChosenDateTime)
        }
    }

    #reloadTable = async () => {
        this.#table = null

        $("#placeForecast").empty();
        const table = $(`
			<table>
				<tbody>
					<tr class="dates"></tr>
					<tr class="hours"></tr>
					<tr class="icons placeDetailHideable"></tr>
					<tr class="temps placeDetailHideable"></tr>
					<tr class="winds placeDetailHideable"></tr>
				</tbody>
			</table>
		`);
        if(this.#isTouchscreen) {
            table.css("margin-left", (window.innerWidth/2) + "px")
            table.css("margin-right", (window.innerWidth/2) + "px")
        } else {
            let leftColWidth = $("#placeDetailData").width()
            table.css("margin-left", leftColWidth + "px")
            table.css("margin-right", 20 + "px")
        }

        // fetch data url
        let json
        let timezone
        const unit = this.#unit;
        const now = DateTime.now()
        if(this.#layer === "rain") {
            if(this.#lat !== null && this.#lon !== null) {
                timezone = (await this.#apiCache.point(this.#lat, this.#lon))[1]
            } else {
                timezone = DateTime.now().zoneName
            }
            json = {hourly: {data: []}}
            let now10minutes = DateTime.now().setZone(timezone).startOf("minute")
            now10minutes = now10minutes.minus({minutes: now10minutes.minute % 10})

            for (let i = -120; i < 60; i+=10)
                json.hourly.data.push({date: now10minutes.plus({minutes: i}).toUTC().toISO().substr(0, 16)})
        } else if (this.#lat !== null && this.#lon !== null) {
            [json, timezone] = await this.#apiCache.point(this.#lat, this.#lon)
        } else {
            timezone = now.zoneName
            json = {hourly: {data: []}}
            for (let i = 0; i < 8 * 24; i++)
                json.hourly.data.push({date: now.plus({hours: i}).startOf("hour").toUTC().toISO().substr(0, 16)})
        }

        // get first item
        const first = json.hourly.data[0];
        const firstDate = new Date(first.date);

        let lastDayProcessed = null
        let today = DateTime.now().setZone(timezone).toISO().substr(0, 10)
        let tomorrow = DateTime.now().setZone(timezone).plus({days: 1}).toISO().substr(0, 10)

        let availTimes = getAvailableTimes(timezone, this.#activeLayer)[0]
        let availTimesSet = {}
        for(let t of availTimes)
            availTimesSet[t] = 1

        // count num of blocks in each day
        let day2count = {"forecast": 0, "history": 0}
        this.#tableDateTimes = []
        for (const ant in json.hourly.data) {
            const data = json.hourly.data[ant];
            const date = DateTime.fromISO(data.date + "Z").setZone(timezone);
            if(date <= now)
                day2count.history++
            else
                day2count.forecast++

            if ((ant % 3) !== 0) continue;

            const day = DateTime.fromISO(data.date + "Z").setZone(timezone).toISO().substr(0, 10);
            if (!availTimesSet[date.toMillis()])
                continue;
            if (day in day2count)
                day2count[day]++
            else
                day2count[day] = 1
        }

        let rainHistoryRendered = false
        let rainForecastRendered = false
        this.#tdHourElements = []
        for (const ant in json.hourly.data) {
            if(this.#layer !== "rain")
                if ((ant % 3) !== 0) continue;

            // get data
            const data = json.hourly.data[ant];

            // date
            const date = DateTime.fromISO(data.date + "Z").setZone(timezone);

            if (!availTimesSet[date.toMillis()])
                continue;

            this.#tableDateTimes.push(date.toJSDate().getTime())


            const currentDay = date.toISO().substr(0, 10)

            let addLineSeparator = (this.#layer !== "rain") && (currentDay !== lastDayProcessed) && (lastDayProcessed !== null)
            addLineSeparator |= (this.#layer === "rain") && !rainForecastRendered && date >= now
            const lineSeparatorDay = addLineSeparator ? ' class="line-separate"' : ''
            const lineSeparatorOther = addLineSeparator ? ' class="line-separate date-' + date + '"' : ' class="date-' + date + '"'
            const lineSeparatorHideable = addLineSeparator ? ' class="placeDetailHideable line-separate date-' + date + '"' : ' class="placeDetailHideable date-' + date + '"'
            if (this.#layer !== "rain" && currentDay !== lastDayProcessed) {
                const colspan = day2count[currentDay]
                const text = (colspan > 2) ? formatDate(true, this.#lang, date, timezone) : formatDate(false, this.#lang, date, timezone);
                table.find(`tr.dates`).append($(`<td colspan='${colspan}'${lineSeparatorDay}>${text}</td>`))

                lastDayProcessed = currentDay
            }
            if(this.#layer === "rain" && !rainHistoryRendered) {
                rainHistoryRendered = true
                table.find(`tr.dates`).append($(`<td colspan='${day2count.history}'${lineSeparatorDay}>${i18next.t('history')}</td>`))
            }
            if(this.#layer === "rain" && !rainForecastRendered && date >= now) {
                rainForecastRendered = true
                table.find(`tr.dates`).append($(`<td colspan='${day2count.forecast}'${lineSeparatorDay}>${i18next.t('forecast')}</td>`))
            }

            //hours
            let label = this.#layer !== "rain" ? date.hour : date.minute === 0 ? date.hour + ':0' + date.minute : date.hour + ':' + date.minute
            let tdHour = $(`<td data-date="${date.toMillis()}" data-hour="${date}"${lineSeparatorOther}>${label}</td>`)
            table.find(`tr.hours`).append(tdHour)
            tdHour.on("mouseenter", () => this.#focused(date))
            tdHour.on("mouseleave", () => this.#unfocused(date))
            tdHour.on("click", () => this.#chosen(date))
            this.#tdHourElements.push(tdHour)

            //icons
            let tdIcon = $(`<td data-icon-id="${data.icon}"${lineSeparatorHideable}><div class="icon icon${data.icon}" ></div></td>`)
            table.find(`tr.icons`).append(tdIcon)
            tdIcon.on("mouseenter", () => this.#focused(date))
            tdIcon.on("mouseleave", () => this.#unfocused(date))
            tdIcon.on("click", () => this.#chosen(date))

            //temps
            let tdTemp = $(`<td data-temp="${data.temperature}"${lineSeparatorHideable}>${Math.round(data.temperature)} ${LayerConfig.getUnitForType(LayerConfig.TYPE_TEMPERATURE, unit)}</td>`)
            table.find(`tr.temps`).append(tdTemp)
            tdTemp.on("mouseenter", () => this.#focused(date))
            tdTemp.on("mouseleave", () => this.#unfocused(date))
            tdTemp.on("click", () => this.#chosen(date))

            //winds
            let tdWind
            if(data.wind) {
                let speed = data.wind.speed
                let speedSpace = " ";
                let gustsSpace = "";
                let gusts
                if(this.#areGustsSignificant(data.wind.speed, data.wind.gusts)) {
                    gusts = "("+Math.ceil(data.wind.gusts)+")";
                    speed = Math.ceil(speed);
                    if(data.wind.speed > 9 && data.wind.gusts > 9)
                        speedSpace = gustsSpace = "";
                } else {
                    gusts = "";
                }
                tdWind = $(`
                    <td data-temp="${speed}"${lineSeparatorHideable}>
                        <span><img src="./arrow-down.png" class="wind-arrow">${speedSpace}${speed}</span>${gustsSpace}<span class="dir">${gusts}</span>
                    </td>
                `)
                let img = tdWind.find("img").first()

                if(!this.#isWindSignificant(speed))
                    img.attr("src", "./no-wind.png")
                else
                    img.css("transform", "rotate(" + data.wind.angle + "deg)")
            } else {
                tdWind = $(`
                    <td data-temp=""${lineSeparatorHideable}>
                    </td>
                `)
            }
            table.find(`tr.winds`).append(tdWind);
            tdWind.on("mouseenter", () => this.#focused(date))
            tdWind.on("mouseleave", () => this.#unfocused(date))
            tdWind.on("click", () => this.#chosen(date))

        }

        $("#placeForecast").append(table);
        this.#chosenDateTime = null
        this.#table = table
    }

    #windToMpS = windSpeed => {
        if (this.#unit === "us") {
            return windSpeed / 2.23694
        } else if (this.#unit === "ca") {
            return windSpeed / 3.6
        } else if(this.#unit === "metric") {
            return windSpeed
        } else {
            return windSpeed
        }
    }

    #isWindSignificant = windSpeed => this.#windToMpS(windSpeed) > 1

    #areGustsSignificant = (windSpeed, gustsSpeed) => (this.#windToMpS(gustsSpeed) - this.#windToMpS(windSpeed)) > 3

    #chosen = (date) => {
        this.#dateChosenCb(date.toMillis())
    }

    #focused = (date) => {
        $(".date-" + date).addClass("focused")
    }

    #unfocused = (date) => {
        $(".date-" + date).removeClass("focused")
    }

    /**
     * Show table
     */
    showDetailsTable = async () => {
        this.#detailsShown = true
        if(this.#layer === "rain")
            return
        $(".placeDetailHideable").css("display", "");
        JQueryNodes.elements.placeDetailClose.removeClass("hide")
        JQueryNodes.elements.placeDetailLabels.removeClass("hide")
        JQueryNodes.elements.playButton.removeClass("hide")
        JQueryNodes.elements.loadingLabel.removeClass("hide")
    }

    hideDetailsTable = async () => {
        this.#detailsShown = false
        $(".placeDetailHideable").css("display", "none");
        JQueryNodes.elements.placeDetailClose.addClass("hide")
        JQueryNodes.elements.placeDetailLabels.addClass("hide")
        JQueryNodes.elements.playButton.addClass("hide")
        JQueryNodes.elements.loadingLabel.addClass("hide")
    }

    showLoadingLabel = doShow => {
        if(doShow)
            JQueryNodes.elements.loadingLabel.removeClass("hideNotLoading")
        else
            JQueryNodes.elements.loadingLabel.addClass("hideNotLoading")
    }

    #getCentralDate = () => {
        if(this.#tdHourElements.length === 0)
            return null

        let l = 0
        let r = this.#tdHourElements.length - 1
        let target = window.innerWidth/2
        let func = i => this.#tdHourElements[i].offset().left

        let idx = this.#getCentralDataBSearch(l, r, target, func)
        return +this.#tdHourElements[idx].attr("data-date")
    }

    #getCentralDataBSearch = (l, r, target, func) => {
        if (target < func(l)) {return l}
        if (target > func(r)) {return r}

        const mid = Math.floor((r + l) / 2);

        return r - l < 2
         ? (target - func(l)) < (func(r) - target) ? l : r
         : target < func(mid)
           ? this.#getCentralDataBSearch(l, mid, target, func)
           : target > func(mid)
             ? this.#getCentralDataBSearch(mid, r, target, func)
             : func(mid)
    }
}